// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import type { ComponentType } from "svelte";
import { createInertiaApp } from "@inertiajs/svelte";

import "@fontsource-variable/inter";

import AppLayout from "$layouts/AppLayout.svelte";
import AdminLayout from "$layouts/AdminLayout.svelte";

/* Polyfills */

import * as polyfills from "$lib/polyfills";

if (typeof window.showOpenFilePicker !== "function") {
  window.showOpenFilePicker = polyfills.showOpenFilePicker;
}

/* App initialization */

const views = import.meta.glob<ComponentType>("../views/**/*.svelte", {
  eager: true,
});

createInertiaApp({
  resolve: (name) => {
    const view = views[`../views/${name}.svelte`] as ComponentType & {
      layout?: unknown;
    };

    let layout = view.layout;

    if (layout === null) {
      // View explicitly does not want a layout.
      return view;
    }

    layout ??= name.startsWith("admin/") ? [AppLayout, AdminLayout] : AppLayout;

    // Set the default layout if view doesn't have one specified.
    return { ...view, layout };
  },
  setup({ el, App, props }) {
    new App({ target: el, props });
  },
});
