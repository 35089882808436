<script lang="ts">
  import { setContext } from "svelte";
  import type { Writable } from "svelte/store";
  import type { InertiaForm } from "@inertiajs/svelte";

  import Input from "$components/forms/Input.svelte";
  import AvatarInput from "$components/forms/AvatarInput.svelte";

  import { Label } from "$components/ui/label";

  export let form: Writable<
    InertiaForm<{ name: string; image?: string | File }>
  >;

  setContext("form", form);
</script>

<div class="flex items-center gap-4 w-full">
  <AvatarInput
    name="image"
    value={$form.image}
    accept="image/jpeg,image/png,image/webp"
    wrapperClass="flex-shrink-0"
    previewClass="w-16 h-16"
    hasPreview
  />
  <div class="w-full space-y-1">
    <Label class="mb-1" required>Name</Label>
    <Input name="name" />
  </div>
</div>
