export type ShowOpenFilePickerOpts = {
  multiple?: boolean;
  types?: Array<{ description?: string; accept: Record<string, string[]> }>;
};

// Only some fields can be polyfilled.
export type FilePickerResult = Array<
  Pick<FileSystemFileHandle, "getFile" | "kind">
>;

// Polyfill for Firefox and Safari.
// Compatibility table: https://developer.mozilla.org/en-US/docs/Web/API/Window/showOpenFilePicker#browser_compatibility
// Spec: https://wicg.github.io/file-system-access/#api-showopenfilepicker
export function showOpenFilePicker(
  options?: ShowOpenFilePickerOpts,
): Promise<FilePickerResult> {
  return new Promise((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";

    if (options?.multiple !== undefined) input.multiple = options.multiple;

    if (options?.types !== undefined) {
      input.accept = options.types
        .map((type) => type.accept)
        .flatMap((inst) => Object.keys(inst))
        .join(",");
    }

    input.addEventListener("change", () => {
      let results: FilePickerResult = [];

      if (input.files) {
        results = [...input.files].map((file) => {
          return {
            kind: "file",
            getFile: async () =>
              new Promise((resolve) => {
                resolve(file);
              }),
          };
        });
      }

      resolve(results);
    });

    input.addEventListener("cancel", () => {
      // As specified in the spec.
      reject(AbortSignal.abort().reason);
    });

    input.click();
  });
}

// Simple implementation. Not following File System Access API on purpose.
export async function showDirectoryPicker(): Promise<File[] | null> {
  return new Promise((resolve) => {
    const input = document.createElement("input");
    input.type = "file";
    input.webkitdirectory = true;

    input.addEventListener("change", () => {
      const files = input.files ? Array.from(input.files) : null;
      document.body.removeChild(input);
      resolve(files);
    });

    input.addEventListener("cancel", () => {
      resolve(null);
    });

    document.body.appendChild(input);
    input.click();
  });
}
