<script lang="ts">
  import { scale } from "svelte/transition";
  import { Combobox as ComboboxPrimitive } from "bits-ui";

  import { cn, flyAndScale } from "$lib/utils.js";

  type $$Props = ComboboxPrimitive.ContentProps;

  export let sideOffset: $$Props["sideOffset"] = 4;
  export let inTransition: $$Props["inTransition"] = flyAndScale;
  export let inTransitionConfig: $$Props["inTransitionConfig"] = undefined;
  export let outTransition: $$Props["outTransition"] = scale;
  export let outTransitionConfig: $$Props["outTransitionConfig"] = {
    start: 0.95,
    opacity: 0,
    duration: 50,
  };
  export { className as class };

  let className: $$Props["class"] = undefined;

  className = cn(
    "relative z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md focus:outline-none",
    className,
  );
</script>

<ComboboxPrimitive.Content
  {inTransition}
  {inTransitionConfig}
  {outTransition}
  {outTransitionConfig}
  {sideOffset}
  class={className}
  {...$$restProps}
>
  <div class="w-full p-1">
    <slot />
  </div>
</ComboboxPrimitive.Content>
