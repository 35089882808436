<script lang="ts">
  export let aspectRatio: number;
  export let height: number;
  export let margin: string = "";

  let width: number;

  $: width = aspectRatio * height;
</script>

<!-- The flex-grow rule will make the flex row fill its entire width while
respecting the aspect ratio of all row items content. -->
<div
  class="{$$props.class ?? ''} relative {margin}"
  style:width="{width}px"
  style:flex-grow={100 * aspectRatio}
>
  <!-- Adjust the wrapper to the content aspect ratio. -->
  <span class="block" style="padding-bottom: {100 / aspectRatio}%;"></span>

  <span class="absolute top-0 w-full h-full">
    <slot {width} />
  </span>
</div>
