// JsFromRoutes CacheKey 7991d49b342005de3e7bf4f712e4a9c6
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  download: /* #__PURE__ */ definePathHelper('get', '/asset_packs/:id/download'),
  index: /* #__PURE__ */ definePathHelper('get', '/asset_packs'),
  create: /* #__PURE__ */ definePathHelper('post', '/asset_packs'),
  new: /* #__PURE__ */ definePathHelper('get', '/asset_packs/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/asset_packs/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/asset_packs/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/asset_packs/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/asset_packs/:id'),
}
