<script lang="ts">
  import { createEventDispatcher, onMount, tick } from "svelte";

  import API from "$api";
  import backend from "$lib/backend";
  import { cn } from "$lib/utils";

  import * as Popover from "$components/ui/popover";
  import * as Command from "$components/ui/command";
  import { Button } from "$components/ui/button";

  import CaretUpDownIcon from "~icons/ph/caret-up-down";
  import CheckIcon from "~icons/ph/check";

  export let tagFieldId: number;
  export let selectedValue: string = "";
  export let wrapperClass: string | undefined = undefined;
  export let triggerClass: string | undefined = undefined;
  export let error: string | undefined = undefined;

  const dispatch = createEventDispatcher<{
    clear: undefined;
    update: string;
  }>();

  let open: boolean = false;
  let loading: boolean = false;
  let query: string = "";
  let values: Schema.TagValue[] = [];

  triggerClass = cn(
    "min-w-48 h-auto py-1 pl-2 pr-1.5 bg-white hover:bg-white border-zinc-300 focus-visible:border-primary data-[open=true]:border-primary data-[open=true]:ring-1 data-[open=true]:ring-ring shadow-none font-normal text-zinc-800",
    triggerClass,
  );

  onMount(() => {
    // Initial load of tag values.
    searchValues();
  });

  function searchValues(query: string = "") {
    loading = true;
    backend
      .get(API.assetTagValues.index.path(), {
        tag_field_id: tagFieldId,
        query,
      })
      .then((tagValues: Schema.TagValue[]) => {
        values = tagValues;
        loading = false;
      });
  }

  // We want to refocus the trigger button when the user selects
  // an item from the list so users can continue navigating the
  // rest of the form with the keyboard.
  function closeAndFocusTrigger(triggerId: string) {
    open = false;
    tick().then(() => {
      document.getElementById(triggerId)?.focus();
    });
  }

  function debounce(callback: Function, waitMs: number = 300) {
    let timeout: ReturnType<typeof setTimeout>;

    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => callback(...args), waitMs);
    };
  }

  function onInput(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    if (target.value.trim().length > 2) searchValues(target.value);
  }
</script>

<div class={wrapperClass}>
  <Popover.Root bind:open let:ids>
    <Popover.Trigger asChild let:builder>
      <Button
        builders={[builder]}
        variant="outline"
        role="combobox"
        aria-expanded={open}
        data-open={open}
        class={triggerClass}
      >
        {selectedValue}&nbsp;
        <CaretUpDownIcon
          class="shrink-0 w-4 h-4 ms-auto text-muted-foreground"
        />
      </Button>
    </Popover.Trigger>
    <Popover.Content class="p-0" align="start" sameWidth={true}>
      <Command.Root shouldFilter={false}>
        <Command.Input
          placeholder="Type to search a value"
          on:input={debounce(onInput)}
          bind:value={query}
        />
        <Command.Group>
          {#if loading}
            <Command.Loading>Searching...</Command.Loading>
          {:else}
            {#each values as tagValue}
              <Command.Item
                value={tagValue.value}
                onSelect={(newValue) => {
                  dispatch("update", newValue);
                  closeAndFocusTrigger(ids.trigger);
                  selectedValue = newValue;
                }}
              >
                <CheckIcon
                  class={cn(
                    "mr-2 h-4 w-4",
                    selectedValue !== tagValue.value && "invisible",
                  )}
                />
                {tagValue.value}
              </Command.Item>
            {:else}
              <span class="block py-1 text-center text-zinc-500 italic">
                Nothing found.
              </span>
            {/each}
          {/if}
        </Command.Group>
      </Command.Root>
    </Popover.Content>
  </Popover.Root>

  {#if error}
    <div class="flex gap-1 mt-1">
      <span class="text-xs text-red-500 font-medium">{error}</span>
    </div>
  {/if}
</div>
