<script lang="ts" generics="MembershipType extends { id: number }">
  import { onMount, type ComponentEvents } from "svelte";

  import Row from "$views/admin/team_memberships/_row.svelte";
  import { Checkbox } from "$components/ui/checkbox";
  import * as Table from "$components/ui/table";

  import AddIcon from "~icons/ph/plus";

  export let existing: MembershipType[];
  export let selection: MembershipType[];
  export let labelKey: keyof MembershipType;
  export let valueKey: keyof MembershipType;
  export let title: string;

  // List of MembershipType ids to add to the DB.
  export let toAdd: number[] = [];
  // List of MembershipType ids to remove from the DB.
  export let toRemove: number[] = [];

  // Initial Memberships are those already existing in the DB.
  let memberships: Array<MembershipType | undefined> = existing;

  // List of items that can be selected in the form by the user.
  let selectable: MembershipType[] = [];

  onMount(() => {
    updateSelectable();
  });

  // An item of MembershipType is selectable if:
  // - it is marked for deletion, then the user could restore it.
  // OR
  // - it doesn't exist yet in the DB AND it wasn't already added to the form.
  function updateSelectable() {
    selectable = selection.filter(
      (m) =>
        toRemove.some((id) => id === m.id) ||
        (!existing.some((e) => e.id === m.id) && !toAdd.some((id) => m.id)),
    );
  }

  // `value` is either a Team id or a User id according to what the
  // MembershipType is.
  function remove(value: number) {
    if (existing.some((e) => e.id === value)) {
      toRemove = [...toRemove, value];
    } else if (toAdd.some((id) => id === value)) {
      toAdd = toAdd.filter((id) => id !== value);
    }

    updateSelectable();

    // Update memberships and trigger an UI update.
    memberships = memberships.filter((m) => m?.id !== value);
  }

  // `value` is either a Team id or a User id according to what the
  // MembershipType is.
  function add(value: number) {
    toRemove = toRemove.filter((id) => id !== value);

    if (!existing.some((m) => m.id === value)) toAdd.push(value);
    updateSelectable();
  }

  function onAddRowClick() {
    if (
      memberships.length &&
      memberships[memberships.length - 1] === undefined
    ) {
      // Don't add a new row if there is already one.
      return;
    }

    memberships = [...memberships, undefined];
  }

  function onRowDelete({
    detail,
  }: ComponentEvents<Row<MembershipType>>["delete"]) {
    if (detail.item === undefined) {
      // Removing a new row.
      memberships = memberships.filter((m) => m !== undefined);
    } else {
      remove(detail.item.id);
    }
  }
</script>

<Table.Root wrapperClass="h-64 mt-4 bg-white" class="h-full">
  <Table.Header>
    <Table.Row>
      <Table.Head class="w-16 py-2 pl-4">
        <Checkbox class="bg-white" />
      </Table.Head>
      <Table.Head class="px-3">
        {title}
      </Table.Head>
      <Table.Head></Table.Head>
    </Table.Row>
  </Table.Header>
  <Table.Body class="h-full">
    {#each memberships as membership}
      <Row
        item={membership}
        items={selectable}
        {labelKey}
        {valueKey}
        on:select={({ detail }) => {
          membership = detail.selectedItem;
          add(detail.value);
        }}
        on:delete={onRowDelete}
      />
    {/each}
    <!-- Empty vertical space filler -->
    <Table.Row class="h-full hover:!bg-transparent">
      <Table.Cell></Table.Cell>
    </Table.Row>
  </Table.Body>
  <Table.Footer class="sticky" style="inset-block-end: 0;">
    <Table.Row class="bg-zinc-100">
      <Table.Cell colspan={3} class="py-1 pr-4 text-right">
        <button
          type="button"
          class="disabled:text-zinc-400"
          disabled={!selectable.length}
          on:click={onAddRowClick}
        >
          <AddIcon class="mt-1" />
        </button>
      </Table.Cell>
    </Table.Row>
  </Table.Footer>
</Table.Root>
