<script lang="ts">
  import { ContextMenu as ContextMenuPrimitive } from "bits-ui";
  import { cn, flyAndScale } from "$lib/utils.js";

  type $$Props = ContextMenuPrimitive.ContentProps;

  let className: $$Props["class"] = undefined;
  export let transition: $$Props["transition"] = flyAndScale;
  export let transitionConfig: $$Props["transitionConfig"] = undefined;
  export let el: $$Props["el"] = undefined;
  export { className as class };
</script>

<ContextMenuPrimitive.Content
  {transition}
  {transitionConfig}
  class={cn(
    "z-50 min-w-[8rem] rounded-md border bg-popover p-1 text-popover-foreground shadow-md focus:outline-none",
    className,
  )}
  {...$$restProps}
  on:keydown
  bind:el
>
  <slot />
</ContextMenuPrimitive.Content>
