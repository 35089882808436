import {
  derived,
  get,
  writable,
  type Readable,
  type Writable,
} from "svelte/store";
import { router } from "@inertiajs/svelte";
import { toast } from "svelte-sonner";

import API from "$api";
import backend from "$lib/backend";

export const bagAssets: Writable<number[]> = writable([]); // array of asset ids
export const bagAssetsSet: Readable<Set<number>> = derived(
  bagAssets,
  (updatedBagAssets) => new Set(updatedBagAssets),
);

export function initBagStore() {
  backend
    .get(API.bagAssets.index.path() + ".json")
    .then(({ assets }: { assets: Schema.Asset[] }) => {
      bagAssets.set(assets.map((asset) => asset.id));
    });
}

export async function addToBag(assetsToAdd: Schema.Asset[]) {
  const request = backend
    .post(API.bagAssets.create.path(), {
      asset_ids: assetsToAdd.map((asset) => asset.id),
    })
    .then(({ added_assets_ids }: { added_assets_ids: number[] }) => {
      console.log(added_assets_ids);
      bagAssets.set([...added_assets_ids, ...get(bagAssets)]);

      return added_assets_ids.length;
    });

  toast.promise(request, {
    loading: "Loading...",
    success: (successCount: number) => {
      if (successCount === 0) {
        return "Nothing new to add to your bag.";
      }

      return `${successCount} \
${successCount > 1 ? "assets were" : "asset was"} added to your bag.`;
    },
    error: "Error: could not add assets to bag.",
  });

  return request;
}

export async function removeFromBag(assetsToRemove: Schema.Asset[]) {
  const removeAssetIds = assetsToRemove.map((asset) => asset.id);
  const request = backend
    .delete(API.bagAssets.destroyMultiple.path(), {
      asset_ids: removeAssetIds,
    })
    .then(() => {
      bagAssets.update((currAssets) => {
        return currAssets.filter(
          (assetId) => !removeAssetIds.includes(assetId),
        );
      });

      // Refresh the current page every time an asset is removed from the bag.
      // On the assets index page, this will update the hint in the corner of
      // the asset.
      // On the bag page, this will remove the relevant assets from the view.
      router.reload();

      return assetsToRemove.length;
    });

  toast.promise(request, {
    loading: "Loading...",
    success: (successCount: number) => {
      return `${successCount} \
${successCount > 1 ? "assets were" : "asset was"} removed from your bag.`;
    },
    error: "Error: could not remove assets from bag.",
  });

  return request;
}

export async function clearBag() {
  const request = backend.delete(API.bagAssets.clear.path()).then(() => {
    bagAssets.set([]);

    // Same reason as in `removeFromBag` above.
    router.reload();
  });

  toast.promise(request, {
    loading: "Loading...",
    success: () => {
      return "Bag successfully cleared.";
    },
    error: "Error: could not clear bag.",
  });

  return request;
}
