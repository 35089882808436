// JsFromRoutes CacheKey e919e6cd7d345144c2fb506913204cd9
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/publications'),
  create: /* #__PURE__ */ definePathHelper('post', '/publications'),
  new: /* #__PURE__ */ definePathHelper('get', '/publications/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/publications/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/publications/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/publications/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/publications/:id'),
}
