<script lang="ts">
  import API from "$api";
  import { currentUser } from "$stores/user";

  import UserImage from "$components/UserImage.svelte";

  import * as DropdownMenu from "$components/ui/dropdown-menu";

  import LogoutIcon from "~icons/ph/sign-out";
</script>

{#if $currentUser}
  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <UserImage user={$currentUser} />
    </DropdownMenu.Trigger>
    <DropdownMenu.Content class="w-56">
      <DropdownMenu.Label>My Account</DropdownMenu.Label>
      <DropdownMenu.Separator />
      <DropdownMenu.Group>
        <DropdownMenu.Item>
          Profile
          <DropdownMenu.Shortcut>⇧⌘P</DropdownMenu.Shortcut>
        </DropdownMenu.Item>
      </DropdownMenu.Group>
      <DropdownMenu.Separator />
      <DropdownMenu.Item>Support</DropdownMenu.Item>
      <DropdownMenu.Separator />
      <DropdownMenu.Item href={API.sessions.destroy.path()}>
        <LogoutIcon class="me-2" />
        Log out
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
{/if}
