<script lang="ts">
  import { slide } from "svelte/transition";

  import API from "$api";

  import ResponsiveAssetImage from "$components/ResponsiveAssetImage.svelte";

  import { Button } from "$components/ui/button";
  import * as Breadcrumb from "$components/ui/breadcrumb";

  import CommentIcon from "~icons/ph/chat-text";
  import ArrowLeftIcon from "~icons/ph/arrow-left";

  export let asset: Schema.Asset;

  let showSideBar: boolean = true;
</script>

<section class="flex flex-col w-full h-full">
  <header
    class="flex-shrink-0 flex items-center h-12 px-2 bg-white border-b border-zinc-300"
  >
    <Button size="sm" variant="ghost" on:click={() => history.back()}>
      <ArrowLeftIcon />
    </Button>

    <Breadcrumb.Root class="w-1/2">
      <Breadcrumb.List>
        <Breadcrumb.Item>
          <Breadcrumb.Link href={API.assets.index.path()}>
            Assets
          </Breadcrumb.Link>
        </Breadcrumb.Item>
        <Breadcrumb.Separator />
        <Breadcrumb.Item>
          <Breadcrumb.Page class="truncate">{asset.name}</Breadcrumb.Page>
        </Breadcrumb.Item>
      </Breadcrumb.List>
    </Breadcrumb.Root>

    <Button
      size="sm"
      variant="outline"
      class="ms-auto"
      on:click={() => (showSideBar = !showSideBar)}
    >
      <CommentIcon />
    </Button>
  </header>

  <main class="flex-grow flex w-full h-full min-h-0">
    <div class="flex-grow flex items-center justify-center h-full">
      <ResponsiveAssetImage
        {asset}
        width="9999"
        class="max-w-full max-h-full"
      />
    </div>

    {#if showSideBar}
      <aside
        class="flex-shrink-0 w-1/4 bg-zinc-100 border-l border-zinc-300"
        transition:slide={{ axis: "x" }}
      >
        hello
      </aside>
    {/if}
  </main>
</section>
