<script context="module" lang="ts">
  import LoginLayout from "$layouts/LoginLayout.svelte";

  export const layout = LoginLayout;
</script>

<script lang="ts">
  import { inertia, useForm } from "@inertiajs/svelte";

  import API from "$api";

  import Input from "$components/forms/Input.svelte";

  import { Button } from "$components/ui/button";
  import * as Alert from "$components/ui/alert";

  import ErrorIcon from "~icons/ph/x-circle-fill";
  import ArrowLeftIcon from "~icons/ph/arrow-left";

  const form = useForm({
    email: null,
  });

  function onFormSubmit() {
    $form.post(API.passwordResets.create.path());
  }
</script>

<div class="pb-4">
  <div class="flex items-center -my-2">
    <div class="w-1/6">
      <a
        href={API.sessions.new.path()}
        class="inline-flex items-center py-2 px-3 hover:bg-zinc-200/50 rounded-md"
        use:inertia
      >
        <ArrowLeftIcon class="mt-0.5" />
      </a>
    </div>
    <h1 class="w-4/6 text-lg font-semibold text-center">Password Reset</h1>
  </div>

  <p class="mt-6 text-zinc-600">
    Kindly enter your e-mail address below. We will send you a link in order to
    reset your password.
  </p>
</div>

{#if $form.hasErrors}
  <Alert.Root variant="destructive">
    <ErrorIcon />
    <Alert.Title>Error</Alert.Title>
    <Alert.Description>
      {$form.errors.email}
    </Alert.Description>
  </Alert.Root>
{/if}

<form class="mt-2" on:submit|preventDefault={onFormSubmit}>
  <fieldset class="flex flex-col gap-4">
    <Input
      type="email"
      name="email"
      placeholder="E-mail address"
      bind:value={$form.email}
    />

    <Button type="submit">Send</Button>
  </fieldset>
</form>
