// JsFromRoutes CacheKey e23607eb645060fa668d577372ab3b88
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/users'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/users'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/users/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/users/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/users/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/users/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/users/:id'),
}
