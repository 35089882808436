<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";

  import { cn } from "$lib/utils";
  import {
    clear,
    registerItem,
    select,
    selection,
    unselect,
    type SelectOptions,
  } from "$stores/selection";

  export let id: number;
  export let draggable: boolean = false;

  const dispatch = createEventDispatcher<{ change: { id: number } }>();

  let wrapper: HTMLDivElement;
  let className: string = cn("focus:outline-none focus:ring-0", $$props.class);

  let selected: boolean = false;
  $: selected = $selection.has(id);

  let clickTimer: ReturnType<typeof setTimeout>;

  function toggle(event: MouseEvent) {
    const opts: SelectOptions = {
      shift: event.shiftKey,
      ctrl: event.ctrlKey || event.metaKey,
    };

    if (selected) unselect(id, opts);
    else select(id, opts);

    dispatch("change", { id });
  }

  // This function unselect all Selection Item except this one.
  function clearRange() {
    clear({ except: id });
    dispatch("change", { id }); // inform any parent of the Selection change
  }

  // This function detects if the click is a single-click or double-click.
  // When a click follows another under 200ms, it's considered a double-click.
  function onClick(event: MouseEvent) {
    clearTimeout(clickTimer);

    if (event.detail > 1) {
      // At this point, this Selection Item is selected (via the first click).

      // User double-clicked on this Selection Item but there is a range
      // selection. Clear it and keep this Selection Item selected.
      if ($selection.size > 1) clearRange();

      wrapper.ondblclick && wrapper.ondblclick(event);
    } else {
      if (!selected) {
        // Immediately select this Selection Item.
        toggle(event);
      } else {
        // When this Selection Item is already selected, there are multiple
        // special cases to take into account.
        // Note that a regular click on an already selected Selection Item does
        // not trigger any reaction, i.e. it stays selected.

        if ($selection.size > 1) {
          // When a range is selected.

          if (event.shiftKey || event.ctrlKey || event.metaKey) {
            // This will forward the range action if it's a single click.
            clickTimer = setTimeout(() => toggle(event), 200);
          } else {
            // User single-clicked on this Selection Item but there is a range
            // selection. Clear it and keep this Selection Item selected.
            clearRange();
          }
        }
      }
    }
  }

  function onRightClick(event: MouseEvent) {
    if (selected) {
      // User right-clicked on this Selection Item but there is a range
      // selection. Clear it and keep this Selection Item.
      clear({ except: id });
      // Inform any parent of this Selection change.
      dispatch("change", { id });
    } else {
      clear();
      toggle(event);
    }
  }

  onMount(() => {
    registerItem(id);
  });
</script>

<div
  class={className}
  data-selection-id={id}
  role="option"
  aria-selected={selected}
  tabindex="0"
  {draggable}
  on:click={onClick}
  on:dblclick
  on:contextmenu={onRightClick}
  on:keydown={null}
  on:drag
  on:dragstart
  on:dragend
  on:dragover
  bind:this={wrapper}
>
  <slot {selected} />
</div>
