<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";

  import API from "$api";
  import backend, { ValidationError } from "$lib/backend";

  import TagsForm from "$components/TagsForm.svelte";

  import Spinner from "$components/Spinner.svelte";

  import * as Dialog from "$components/ui/dialog";
  import { Button } from "$components/ui/button";
  import { Label } from "$components/ui/label";
  import * as RadioGroup from "$components/ui/radio-group";

  import XIcon from "~icons/ph/x";

  export let assets: Schema.Asset[];

  const dispatch = createEventDispatcher<{ destroy: undefined }>();

  let tagFields: Schema.TagField[] | undefined = undefined;
  let tags: NewRecord<Schema.Tag>[] = [];
  let mode: "upsert" | "replace" = "upsert";
  let enforceRequired: boolean;
  $: enforceRequired = mode === "replace";

  let open: boolean = false;
  let saving: boolean = false;

  onMount(() => {
    open = true;

    backend
      .get(API.tagFields.index.path())
      .then((newTagFields: Schema.TagField[]) => (tagFields = newTagFields));
  });

  function destroy() {
    open = false;
    // Emit `cancel` event after a delay to allow closing animation to finish.
    setTimeout(() => dispatch("destroy"), 400);
  }

  function onCancelClick() {
    destroy();
  }

  function onOpenChange(open: boolean) {
    if (!open) destroy();
  }

  function onSaveClick() {
    saving = true;

    const filledTags = tags.filter(
      (tag) =>
        tag.value.trim() !== "" ||
        (mode === "replace" && tag.tag_field?.required),
    );

    backend
      .put(API.assetTags.batchUpdate.path(), {
        batch: {
          mode,
          asset_ids: assets.map((asset) => asset.id),
          tags: filledTags.map((tag) => {
            return {
              tag_field_id: tag.tag_field_id,
              value: tag.value,
            };
          }),
        },
      })
      .then(() => {
        open = false;
        toast.success(`Tags successfully edited on ${assets.length} assets.`);
      })
      .catch((error) => {
        if (error instanceof ValidationError) {
          const returnedTags = error.data as typeof tags;

          tags = tags.map((tag) => {
            const returnedTag = returnedTags.find(
              (rtag) => rtag.tag_field_id === tag.tag_field_id,
            );
            return returnedTag ?? tag;
          });
        }
      })
      .finally(() => {
        saving = false;
      });
  }
</script>

<Dialog.Root {onOpenChange} bind:open>
  <Dialog.Portal>
    <Dialog.Overlay class="bg-black/60 backdrop-blur-none" />

    <Dialog.Content
      class="block md:w-1/2 xl:w-1/4 max-w-none p-0 bg-zinc-100 border-none"
    >
      <Dialog.Header class="flex-row space-y-0 bg-primary rounded-t-md">
        <Dialog.Title class="p-4 text-zinc-50 font-semibold">
          Batch edit tags of {assets.length}
          {assets.length === 1 ? "asset" : "assets"}
        </Dialog.Title>
        <Dialog.Close class="ms-auto">
          <XIcon />
        </Dialog.Close>
      </Dialog.Header>

      <div class="p-4">
        <span class="font-medium">Mode</span>

        <RadioGroup.Root class="pt-2" bind:value={mode}>
          <div
            class="flex gap-4 p-4 border-2 rounded-lg"
            class:border-primary={mode === "upsert"}
          >
            <RadioGroup.Item
              class={mode === "upsert" ? "bg-primary text-white" : undefined}
              value="upsert"
              id="upsert"
            />
            <div class="-mt-1">
              <Label for="upsert">Update or add</Label>
              <p class="text-sm text-zinc-500">
                If the tags below are already present, change their value.
                Otherwise, add them.
              </p>
            </div>
          </div>

          <div
            class="flex gap-4 p-4 border-2 rounded-lg"
            class:border-primary={mode === "replace"}
          >
            <RadioGroup.Item
              class={mode === "replace" ? "bg-primary text-white" : undefined}
              value="replace"
              id="replace"
            />
            <div class="-mt-1">
              <Label for="replace">
                Replace
                <span
                  class="ms-1 py-1 px-1.5 bg-amber-600 rounded text-xs uppercase font-medium text-amber-50"
                  class:hidden={mode === "upsert"}
                >
                  Warning: Destructive operation
                </span>
              </Label>
              <p class="text-sm text-zinc-500">
                Any existing tags will be replaced with the ones below.
              </p>
            </div>
          </div>
        </RadioGroup.Root>

        {#if !tagFields || !tags}
          <div class="flex items-center mt-4">
            <Spinner class="me-2" /> Loading tags...
          </div>
        {:else}
          {#key enforceRequired}
            <TagsForm
              {tagFields}
              {enforceRequired}
              sectionClass="mt-4 text-sm"
              tableClass="w-full"
              bind:tags
            />
          {/key}
        {/if}
      </div>

      <Dialog.Footer
        class="p-2 bg-zinc-200 border-t border-zinc-300 rounded-b-md"
      >
        <Button
          variant="secondary"
          class="hover:bg-zinc-300"
          on:click={onCancelClick}
        >
          Cancel
        </Button>
        <Button on:click={onSaveClick} disabled={saving}>
          {#if saving}
            <Spinner />
          {:else}
            Save
          {/if}
        </Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
