<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { useForm } from "@inertiajs/svelte";
  import { toast } from "svelte-sonner";

  import TagValueForm from "$views/admin/tag_values/_form.svelte";

  import API from "$api";

  import * as Dialog from "$components/ui/dialog";
  import { Button } from "$components/ui/button";
  import * as Toaster from "$components/ui/sonner";

  import XIcon from "~icons/ph/x";

  export let tag_value: Schema.TagValue;

  const dispatch = createEventDispatcher<{ destroy: undefined }>();
  const form = useForm(tag_value);

  let open: boolean = false;
  let saving: boolean = false;

  onMount(() => {
    open = true;
  });

  function destroy() {
    open = false;
    // Emit `cancel` event after a delay to allow closing animation to finish.
    setTimeout(() => dispatch("destroy"), 400);
  }

  function onCancelClick() {
    destroy();
  }

  function onOpenChange(open: boolean) {
    if (!open) destroy();
  }

  function onSaveClick() {
    // Namespace the form data before sending it to the backend, as Rails
    // requires it (strong parameters).
    const railsForm = $form.transform((data: Record<string, unknown>) => {
      return { tag_value: data };
    });

    railsForm.put(API.adminTagValues.update.path({ id: tag_value.id }), {
      onSuccess: () => {
        saving = false;
        open = false;
        toast.success(Toaster.HTMLMessage, {
          componentProps: {
            message: `Predefined value was updated.`,
          },
        });
      },
    });

    saving = true;
  }
</script>

<Dialog.Root {onOpenChange} bind:open>
  <Dialog.Trigger />
  <Dialog.Portal>
    <Dialog.Overlay class="bg-black/60 backdrop-blur-none" />

    <Dialog.Content
      class="block md:w-1/2 xl:w-1/4 max-w-none p-0 bg-zinc-100 border-none overflow-hidden"
    >
      <Dialog.Header class="flex-row space-y-0 bg-primary">
        <Dialog.Title class="p-4 text-zinc-50 font-semibold">
          Edit Predefined Tag Value
        </Dialog.Title>
        <Dialog.Close class="ms-auto">
          <XIcon />
        </Dialog.Close>
      </Dialog.Header>

      <div class="py-6 px-4">
        <TagValueForm {form} />
      </div>

      <Dialog.Footer class="p-2 bg-zinc-200 border-t border-zinc-300">
        <Button
          variant="secondary"
          class="hover:bg-zinc-300"
          on:click={onCancelClick}
        >
          Cancel
        </Button>
        <Button on:click={onSaveClick}>Save</Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
