// JsFromRoutes CacheKey 4d0938dbad53818bf72a2adec3618d6a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  download: /* #__PURE__ */ definePathHelper('get', '/assets/:id/download'),
  index: /* #__PURE__ */ definePathHelper('get', '/assets'),
  create: /* #__PURE__ */ definePathHelper('post', '/assets'),
  new: /* #__PURE__ */ definePathHelper('get', '/assets/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/assets/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/assets/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/assets/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/assets/:id'),
}
