<script context="module" lang="ts">
  import ConfirmationDialog from "./ConfirmationDialog.svelte";

  export function triggerConfirmation(
    props: ComponentProps<ConfirmationDialog>,
  ): Promise<{ confirmed: boolean }> {
    return new Promise((resolve, _) => {
      props.open ??= true;

      const dialog = new ConfirmationDialog({
        target: document.body,
        props,
      });

      dialog.$on("cancel", () => resolve({ confirmed: false }));
      dialog.$on("confirm", () => resolve({ confirmed: true }));
    });
  }
</script>

<script lang="ts">
  import { createEventDispatcher, type ComponentProps } from "svelte";

  import * as AlertDialog from "$components/ui/alert-dialog";

  export let title: string | undefined = undefined;
  export let description: string;
  export let cancelBtnLabel: string = "Cancel";
  export let confirmBtnLabel: string = "Continue";
  export let open: boolean = false;
  export let cancellable: boolean = true;

  const dispatch = createEventDispatcher<{
    cancel: undefined;
    confirm: undefined;
  }>();
</script>

<AlertDialog.Root bind:open>
  <AlertDialog.Content>
    <AlertDialog.Header>
      {#if title}
        <AlertDialog.Title>{title}</AlertDialog.Title>
      {/if}
      <AlertDialog.Description>
        {@html description}
      </AlertDialog.Description>
    </AlertDialog.Header>

    <AlertDialog.Footer>
      {#if cancellable}
        <AlertDialog.Cancel on:click={() => dispatch("cancel")}>
          {cancelBtnLabel}
        </AlertDialog.Cancel>
      {/if}
      <AlertDialog.Action on:click={() => dispatch("confirm")}>
        {confirmBtnLabel}
      </AlertDialog.Action>
    </AlertDialog.Footer>
  </AlertDialog.Content>
</AlertDialog.Root>
