// JsFromRoutes CacheKey ccdb2eba8b84c3826bad1b2496e8c2d8
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/tag_values'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/tag_values'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/tag_values/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/tag_values/:id'),
}
