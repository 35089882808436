import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { cubicOut } from "svelte/easing";
import type { TransitionConfig } from "svelte/transition";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type FlyAndScaleParams = {
  y?: number;
  x?: number;
  start?: number;
  duration?: number;
};

export const flyAndScale = (
  node: Element,
  params: FlyAndScaleParams = { y: -8, x: 0, start: 0.95, duration: 150 },
): TransitionConfig => {
  const style = getComputedStyle(node);
  const transform = style.transform === "none" ? "" : style.transform;

  const scaleConversion = (
    valueA: number,
    scaleA: [number, number],
    scaleB: [number, number],
  ) => {
    const [minA, maxA] = scaleA;
    const [minB, maxB] = scaleB;

    const percentage = (valueA - minA) / (maxA - minA);
    const valueB = percentage * (maxB - minB) + minB;

    return valueB;
  };

  const styleToString = (
    style: Record<string, number | string | undefined>,
  ): string => {
    return Object.keys(style).reduce((str, key) => {
      if (style[key] === undefined) return str;
      return str + `${key}:${style[key]};`;
    }, "");
  };

  return {
    duration: params.duration ?? 200,
    delay: 0,
    css: (t) => {
      const y = scaleConversion(t, [0, 1], [params.y ?? 5, 0]);
      const x = scaleConversion(t, [0, 1], [params.x ?? 0, 0]);
      const scale = scaleConversion(t, [0, 1], [params.start ?? 0.95, 1]);

      return styleToString({
        transform: `${transform} translate3d(${x}px, ${y}px, 0) scale(${scale})`,
        opacity: t,
      });
    },
    easing: cubicOut,
  };
};

/**
 * This function builds a range as a numeric array.
 *
 * @param {number} begin - The start of the desired range.
 * @param {number} end - The end of the desired range.
 * @param {number} [step] - The pace at which the range items increase or
 *   decrease.
 * @param {boolean} [includeBegin] - Whether to include the beginning number.
 *   Default to `true`.
 * @param {boolean} [includeEnd] - Whether to include the ending number. Default
 *   to `true`.
 * @returns {number[]} The numeric range.
 */
export function range(
  begin: number,
  end: number,
  opts: { step?: number; includeBegin?: boolean; includeEnd?: boolean } = {
    step: 1,
    includeBegin: true,
    includeEnd: true,
  },
): number[] {
  if (opts.step === 0) return [];

  opts.step ??= 1;
  opts.includeBegin ??= true;
  opts.includeEnd ??= true;

  let diff;
  let total;

  if (begin <= end) {
    // Normal direction
    diff = end - begin;
  } else {
    // Reverse direction
    opts.step = opts.step * -1;
    diff = begin - end;
  }

  total = Math.ceil(diff / Math.abs(opts.step));

  if (opts.includeBegin === false) {
    begin += opts.step;
    total--;
  }

  if (opts.includeEnd) {
    end += opts.step;
    total++;
  }

  return [...Array(total)].map((_, i) => begin + i * opts.step);
}

export function nextFrame() {
  return new Promise((resolve) => {
    requestAnimationFrame(() => {
      requestAnimationFrame(resolve);
    });
  });
}

export function sleep(ms: number) {
  return new Promise((r) => setTimeout(r, ms));
}

// Found on: https://stackoverflow.com/a/35820220
export function promiseState<T>(
  promise: Promise<T>,
): Promise<"pending" | "fulfilled" | "rejected"> {
  const pending = Symbol("pending");
  return Promise.race([promise, pending]).then(
    (value) => (value === pending ? "pending" : "fulfilled"),
    () => "rejected",
  );
}

// Inspired from https://blog.webdevsimplified.com/2020-07/relative-time-format
export const relativeDateFmt = new Intl.RelativeTimeFormat(["en-BE", "fr-BE"], {
  style: "long",
});

const DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];

export function formatTimeAgo(date: Date | string) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  const now = new Date();

  let duration = (date - now) / 1000;

  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i];

    if (Math.abs(duration) < division.amount) {
      return relativeDateFmt.format(Math.round(duration), division.name);
    }

    duration /= division.amount;
  }
}

type ProgressCallback = ({
  loaded,
  total,
  progress,
  percent,
}: {
  delta: number;
  loaded: number;
  total: number;
  progress: number;
  percent: number;
}) => void;

type UploadOptions = {
  onProgress?: ProgressCallback;
};

export async function uploadWithProgress(
  url: string,
  data: FormData,
  opts?: UploadOptions,
): Promise<Response> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const progressCb = opts?.onProgress;
    const csrfToken = document
      .querySelector("meta[name='csrf-token']")
      ?.getAttribute("content");

    if (csrfToken == null) {
      reject(new Error("CSRF token not found."));
      return;
    }

    let prevLoaded: number = 0;

    const handleProgress = (progressCb: ProgressCallback) => {
      return (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
        if (event.lengthComputable) {
          const { loaded, total } = event;
          const progress = loaded / total;
          const percent = Math.ceil(progress * 100);
          const delta = loaded - prevLoaded;

          prevLoaded = loaded;

          progressCb({ delta, loaded, total, progress, percent });
        }
      };
    };

    if (progressCb) {
      xhr.upload.addEventListener("progress", handleProgress(progressCb));
    }

    xhr.addEventListener("loadend", () => {
      const response = new Response(xhr.response, {
        status: xhr.status,
        statusText: xhr.statusText,
      });

      if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 300) {
        resolve(response);
      } else {
        reject(response);
      }
    });

    xhr.addEventListener("error", () => {
      reject(new Error("Failed to fetch with progress."));
    });

    xhr.responseType = "text";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-CSRF-Token", csrfToken);
    xhr.send(data);
  });
}

export function isDeepEqual(object1: any, object2: any) {
  const obj1Keys = Object.keys(object1);
  const obj2Keys = Object.keys(object2);

  if (obj1Keys.length !== obj2Keys.length) return false;

  for (const key of obj1Keys) {
    const value1 = object1[key];
    const value2 = object2[key];
    const nestedObjects = isObject(value1) && isObject(value2);

    if (nestedObjects) {
      if (!isDeepEqual(value1, value2)) return false;
    } else {
      if (value1 !== value2) return false;
    }
  }

  return true;
}

export function isObject(object: any) {
  return object != null && typeof object === "object";
}

export function isObjectRecord(
  object: unknown,
): object is Record<string, Object> {
  return (
    isObject(object) && Object.values(object as Object).some((v) => isObject(v))
  );
}

export function isRecord(object: unknown): object is Record<string, any> {
  return isObject(object);
}

export const datetime = {
  format: function (str: string, opts?: Intl.DateTimeFormatOptions): string {
    const dateFmt = new Intl.DateTimeFormat(["en-BE", "fr-BE"], opts);

    return dateFmt.format(new Date(str));
  },
};
