<script lang="ts">
  import { ValidationError, validateTag } from "$lib/backend";

  import Input from "$components/forms/Input.svelte";
  import TagFieldCombobox from "$components/TagFieldCombobox.svelte";
  import Spinner from "$components/Spinner.svelte";

  import * as Tooltip from "$components/ui/tooltip";

  import FormatIcon from "~icons/ph/question";

  export let tag: Schema.Tag | NewRecord<Schema.Tag>;
  export let enforceRequired: boolean = true;

  let validating: boolean = false;

  async function validate() {
    validating = true;

    return validateTag(tag)
      .then(() => {
        delete tag.errors;
      })
      .catch((error) => {
        if (error instanceof ValidationError) {
          tag = error.data as typeof tag;
        }
      })
      .finally(() => {
        validating = false;
      });
  }
</script>

<tr>
  <td class="w-1/2 text-zinc-500 align-middle">
    {tag.tag_field?.name}
    {#if enforceRequired && tag.tag_field?.required}
      <span class="text-red-600">*</span>
    {/if}
  </td>
  <td class="w-1/2 py-1">
    {#if tag.tag_field?.free_entry || tag.tag_field_id === undefined}
      <Input
        name="tag"
        error={tag.errors && tag.errors.full_messages[0]}
        wrapperClass="w-full py-1 px-2"
        on:blur={validate}
        bind:value={tag.value}
      >
        <div
          class="flex items-center justify-between pt-1 text-xs text-zinc-500"
          slot="hint"
        >
          {#if tag.tag_field?.format}
            <span class="px-1">Format: {tag.tag_field?.format}</span>
            <Tooltip.Root openDelay={0} closeDelay={0}>
              <Tooltip.Trigger class="ms-2">
                <FormatIcon class="text-zinc-800" />
              </Tooltip.Trigger>
              <Tooltip.Content side="bottom" align="end">
                <p>
                  <b>A</b> indicates an uppercase letter.<br /><b>a</b>
                  indicates a lowercase letter.<br /><b>#</b> indicates a digit.
                </p>
              </Tooltip.Content>
            </Tooltip.Root>
          {/if}
        </div>

        <svelte:fragment slot="trailing">
          {#if validating}
            <Spinner size="sm" />
          {/if}
        </svelte:fragment>
      </Input>
    {:else}
      <TagFieldCombobox
        tagFieldId={tag.tag_field_id}
        wrapperClass="w-full"
        triggerClass="w-full"
        error={tag.errors && tag.errors.full_messages[0]}
        bind:selectedValue={tag.value}
      />
    {/if}
  </td>
</tr>
