<script lang="ts">
  import { fade } from "svelte/transition";

  import { cn } from "$lib/utils";

  import DropIcon from "~icons/ph/download-simple";
  import { currentUser } from "$stores/user";

  export let draggingOver: boolean = false;

  $: wrapperClass = cn("relative", $$props.class);

  function onDragEnter(event: DragEvent) {
    // Check if user is dragging Assets. In this case, don't show drop zone.
    try {
      const dragData = event.dataTransfer?.getData("text/plain") ?? "";
      const data = JSON.parse(dragData);
      if (data.assets) return;
    } catch {}

    draggingOver = true;
  }

  function onDragLeave() {
    draggingOver = false;
  }

  function onDragOver(event: MouseEvent) {
    event.preventDefault();
  }

  function onDrop() {
    draggingOver = false;
  }
</script>

{#if $currentUser && ["admin", "contributor"].includes($currentUser.role)}
  <div
    class={wrapperClass}
    class:pointer-events-none={draggingOver}
    on:dragenter={onDragEnter}
    on:dragover={onDragOver}
    on:drop
    on:drop={onDrop}
  >
    <slot />

    {#if draggingOver}
      <div
        class="z-10 absolute top-0 left-0 w-full h-full p-8 bg-black/80 pointer-events-auto"
        on:dragleave={onDragLeave}
        transition:fade
      >
        <div
          class="flex flex-col items-center justify-center gap-8 w-full h-full border-4 border-dashed border-white pointer-events-none"
        >
          <DropIcon class="text-6xl text-white" />

          <span class="text-xl text-white">
            Drop your files here to import them.
          </span>
        </div>
      </div>
    {/if}
  </div>
{:else}
  <slot />
{/if}
