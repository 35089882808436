<script lang="ts">
  import { cn } from "$lib/utils";

  type SizeProps = {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };

  type StrokeProps = {
    zinc: string;
    blue: string;
    red: string;
    green: string;
    white: string;
    primary: string;
  };

  export let size: keyof SizeProps = "md";
  export let color: keyof StrokeProps = "primary";

  const sizesClasses: SizeProps = {
    xs: "w-4 h-4",
    sm: "w-5 h-5",
    md: "w-6 h-6",
    lg: "w-8 h-8",
    xl: "w-10 h-10",
  };

  const strokeClasses: StrokeProps = {
    zinc: "stroke-zinc-800",
    blue: "stroke-blue-500",
    red: "stroke-red-500",
    green: "stroke-emerald-500",
    white: "stroke-white",
    primary: "stroke-primary",
  };

  let iconClass = cn("animate-spin", sizesClasses[size], strokeClasses[color]);
</script>

<div class={$$props.class ?? ""} aria-label="Loading..." role="status">
  <svg
    width="24"
    height="24"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    stroke-linecap="round"
    stroke-linejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    class={iconClass}
  >
    <path
      d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12"
    >
    </path>
  </svg>
</div>
