// JsFromRoutes CacheKey 667951b79407277122e6841edb30ad63
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  validate: /* #__PURE__ */ definePathHelper('post', '/tags/validate'),
  index: /* #__PURE__ */ definePathHelper('get', '/tags'),
  create: /* #__PURE__ */ definePathHelper('post', '/tags'),
  new: /* #__PURE__ */ definePathHelper('get', '/tags/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/tags/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/tags/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/tags/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/tags/:id'),
}
