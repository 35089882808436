// JsFromRoutes CacheKey 194ef1b18b21fe2fa517338938e2a26c
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/login'),
  create: /* #__PURE__ */ definePathHelper('post', '/login'),
  destroy: /* #__PURE__ */ definePathHelper('get', '/logout'),
}
