<script lang="ts">
  import { ValidationError, validateTag } from "$lib/backend";
  import { cn } from "$lib/utils";

  import TagsFormField from "$components/TagsFormField.svelte";

  export let tagFields: Schema.TagField[];

  // Form is only for tags associated with a Tag Field.
  export let tags: Schema.Tag[] | NewRecord<Schema.Tag>[] = [];

  export let sectionClass: string | undefined = undefined;
  export let headerClass: string | undefined = undefined;
  export let mainClass: string | undefined = undefined;
  export let tableClass: string | undefined = undefined;
  export let enforceRequired: boolean = true;

  export async function isValid(): Promise<boolean> {
    let valid: boolean = true;

    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];

      // Blank tags are considered unset and won't be sent to the backend.
      // Except for required tags.
      if (!tag.tag_field?.required && tag.value.trim() === "") continue;

      // Save a call to the backend when tag already has errors
      if (tag.errors?.length) continue;

      await validateTag(tag).catch((error) => {
        if (error instanceof ValidationError) {
          tags[i] = error.data as typeof tag;
          valid = false;
        }
      });
    }

    !valid && (tags = tags); // trigger UI update to show errors

    return valid;
  }

  sectionClass = cn("", sectionClass);
  headerClass = cn("flex items-center pb-1", headerClass);
  mainClass = cn("w-full p-2 bg-zinc-50 border rounded-lg", mainClass);
  tableClass = cn("", tableClass);

  for (const tagField of tagFields) {
    if (tags.some((tag) => tag.tag_field_id === tagField.id)) continue;

    tags = [
      ...tags,
      {
        tag_field: tagField,
        tag_field_id: tagField.id,
        value: "",
      },
    ];
  }
</script>

<section class={sectionClass}>
  <main class={mainClass}>
    <table class={tableClass}>
      <tbody>
        {#each tags as tag}
          <TagsFormField {enforceRequired} bind:tag />
        {:else}
          <tr>
            <td class="text-zinc-500 text-sm font-light text-center">
              No tags yet.
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </main>
</section>
