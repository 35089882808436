<script lang="ts">
  import type { HTMLAttributes } from "svelte/elements";
  import { cn } from "$lib/utils.js";

  type $$Props = HTMLAttributes<HTMLTableSectionElement>;

  let className: $$Props["class"] = undefined;
  export { className as class };

  export let style: string | undefined = undefined;
</script>

<tfoot class={cn("font-medium", className)} {...$$restProps} {style}>
  <slot />
</tfoot>
