<script lang="ts">
  import * as Tooltip from "$components/ui/tooltip";

  export let tooltip: string | undefined = undefined;
</script>

<Tooltip.Root openDelay={150}>
  <Tooltip.Trigger asChild let:builder>
    <div use:builder.action {...builder}>
      <button class="p-0.5 bg-black/50 rounded" on:click on:mouseenter>
        <slot />
      </button>
    </div>
  </Tooltip.Trigger>
  {#if tooltip}
    <Tooltip.Content side="top">
      <p>{tooltip}</p>
    </Tooltip.Content>
  {/if}
</Tooltip.Root>
