<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";

  import * as Dialog from "$components/ui/dialog";
  import { Button } from "$components/ui/button";

  import WarningIcon from "~icons/ph/warning";

  export let title: string = "Error";
  export let content: string | undefined = undefined;

  const dispatch = createEventDispatcher<{ destroy: undefined }>();

  let open: boolean = false;

  onMount(() => {
    open = true;
  });

  function destroy() {
    // Emit `cancel` event after a delay to allow closing animation to finish.
    setTimeout(() => dispatch("destroy"), 400);
  }

  function onOKClick() {
    open = false;
  }

  function onOpenChange(open: boolean) {
    if (!open) destroy();
  }
</script>

<Dialog.Root {onOpenChange} bind:open>
  <Dialog.Portal>
    <Dialog.Content
      class="block md:w-fit p-0 bg-zinc-100 border-zinc-300 overflow-hidden"
    >
      <Dialog.Header class="items-center pt-4">
        <div
          class="flex items-center justify-center w-16 h-16 bg-red-100 rounded-full"
        >
          <WarningIcon class="text-3xl text-red-600" />
        </div>
        <Dialog.Title class="pt-4 px-8 text-primary font-semibold">
          {title}
        </Dialog.Title>
      </Dialog.Header>

      <div class="pt-1 px-8 text-center text-zinc-600">
        {#if content}
          {@html content}
        {:else if $$slots.default}
          <slot />
        {/if}
      </div>

      <Dialog.Footer class="pt-6 p-4">
        <Button
          variant="secondary"
          class="w-full hover:bg-zinc-300"
          on:click={onOKClick}
        >
          OK
        </Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
