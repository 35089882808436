<script context="module" lang="ts">
  import LoginLayout from "$layouts/LoginLayout.svelte";

  export const layout = LoginLayout;
</script>

<script lang="ts">
  import { inertia, useForm } from "@inertiajs/svelte";

  import API from "$api";

  import Input from "$components/forms/Input.svelte";

  import { Button } from "$components/ui/button";
  import * as Alert from "$components/ui/alert";

  import ErrorIcon from "~icons/ph/x-circle-fill";

  const form = useForm({
    email: null,
    password: null,
  });

  function onFormSubmit() {
    $form.post(API.sessions.create.path());
  }
</script>

<div class="pb-4">
  <h1 class="text-lg font-semibold text-center">Welcome</h1>
  <p class="mt-4 text-zinc-600 text-center">
    Please enter your credentials below.
  </p>
</div>

{#if $form.hasErrors}
  <Alert.Root class="mt-4" variant="destructive">
    <ErrorIcon />
    <Alert.Title>Error</Alert.Title>
    <Alert.Description>
      {$form.errors.email}
    </Alert.Description>
  </Alert.Root>
{/if}

<form class="mt-4" on:submit|preventDefault={onFormSubmit}>
  <fieldset class="flex flex-col gap-4">
    <Input
      type="email"
      name="email"
      placeholder="E-mail"
      bind:value={$form.email}
    />
    <Input
      type="password"
      name="password"
      placeholder="Password"
      bind:value={$form.password}
    />
    <Button type="submit">Login</Button>
  </fieldset>
</form>

<div class="flex items-center justify-center w-full mt-4 -mb-4">
  <a
    href={API.passwordResets.new.path()}
    class="group flex items-center py-1.5 px-2 text-sm font-medium hover:bg-zinc-200/75 rounded-md"
    use:inertia
  >
    Forgot password?
  </a>
</div>
