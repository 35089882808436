<script context="module" lang="ts">
  type UploadItem = {
    id: number;
    file: File;
    tags: NewRecord<
      Schema.Tag & { tag_field_id: number; tag_field: Schema.TagField }
    >[];
    status: "pending" | "uploading" | "uploaded" | "error";
    progress: { bytes: number; percent: number };
  };
</script>

<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { cubicInOut } from "svelte/easing";
  import prettyBytes from "pretty-bytes";
  import prettyMs from "humanize-duration";
  import { router, useForm } from "@inertiajs/svelte";

  import ASSET_TYPES from "$data/asset_types.json";

  import API from "$api";
  import backend from "$lib/backend";
  import { uploadWithProgress } from "$lib/utils";
  import { currentUser } from "$stores/user";

  import TagsForm from "$components/TagsForm.svelte";
  import PermissionsForm from "$views/assets/permissions/_form.svelte";

  import Spinner from "$components/Spinner.svelte";

  import * as Dialog from "$components/ui/dialog";
  import * as Alert from "$components/ui/alert";
  import { Button } from "$components/ui/button";
  import { Progress } from "$components/ui/progress";
  import { Separator } from "$components/ui/separator";

  import XIcon from "~icons/ph/x";
  import XCircleIcon from "~icons/ph/x-circle-fill";
  import WarningCircleIcon from "~icons/ph/warning-circle-fill";
  import CheckCircleIcon from "~icons/ph/check-circle-fill";
  import RejectedFilesDialog from "./rejected_files_dialog.svelte";

  export let files: File[] | undefined = undefined;
  export let open: boolean = false;

  const dispatch = createEventDispatcher<{ destroy: undefined }>();
  const allowedMimeTypes = ASSET_TYPES.flatMap((at) => at.mime_types);

  let filesByStatus: { allowed?: File[]; rejected?: File[] } = {};

  let tagFields: Schema.TagField[] | undefined = undefined;
  let tagsForm: TagsForm | undefined = undefined;

  // Only set tags associated with a Tag Field on import.
  let tags: Schema.Tag[] | NewRecord<Schema.Tag>[];

  let working: boolean = false;
  let createdImport: Schema.Import | undefined = undefined;
  let permissionsForm = useForm<{
    permissions: Array<{
      subject: Schema.User | Schema.Team;
    }>;
  }>({ permissions: $currentUser ? [{ subject: $currentUser }] : [] });

  let uploadQueue: UploadItem[] = [];
  let currentUpload: UploadItem | undefined = undefined;
  let totalSize: number = 0;
  let totalProgressSize: number = 0;
  let totalProgressPercent: number = 0;
  let uploadStartTime: Date | undefined = undefined;
  let timeRemainingMs: number | undefined = undefined;
  let uploadDone: boolean = false;
  let erroredUploadCount: number = 0;

  $: totalProgressPercent = Math.floor((100 * totalProgressSize) / totalSize);

  $: if (files) {
    filesByStatus = Object.groupBy(files, ({ type }) => {
      if (allowedMimeTypes.includes(type)) return "allowed";
      else return "rejected";
    });
  }

  onMount(() => {
    backend
      .get(API.tagFields.index.path())
      .then((newTagFields: Schema.TagField[]) => (tagFields = newTagFields));
  });

  function destroy() {
    open = false;
    // Emit `cancel` event after a delay to allow closing animation to finish.
    setTimeout(() => dispatch("destroy"), 400);
  }

  function onCancelClick() {
    destroy();
  }

  function onOpenChange(open: boolean) {
    if (!open) destroy();
  }

  // TODO: handle error
  async function createImport() {
    if (!filesByStatus.allowed) return;

    return backend.post(API.imports.create.path(), {
      import: {
        upload_files: filesByStatus.allowed.map((file) => {
          return { name: file.name, size: file.size, mime_type: file.type };
        }),
      },
    });
  }

  async function onImportClick() {
    working = true;

    if (tagsForm && !(await tagsForm.isValid())) {
      working = false;
      return;
    }

    createdImport = await createImport();
    upload();

    working = false;
  }

  function onOpenImportClick() {
    router.visit(API.imports.show.path({ id: createdImport?.id }));
    destroy();
  }

  async function upload() {
    const uploadFiles = filesByStatus.allowed;
    if (!uploadFiles) return;

    uploadQueue = uploadFiles.map((file, idx) => {
      return {
        id: idx,
        file,
        tags,
        status: "pending",
        progress: { bytes: 0, percent: 0 },
      };
    });

    totalSize = uploadFiles.reduce((accSize, currFile) => {
      return accSize + currFile.size;
    }, 0);
    uploadStartTime = new Date();

    const timer = setInterval(() => {
      if (!uploadStartTime) return;

      const now = new Date();
      const timeElapsedMs = now.valueOf() - uploadStartTime.valueOf();
      const avgUploadSpeed = totalProgressSize / timeElapsedMs;
      const remainingSize = totalSize - totalProgressSize;

      timeRemainingMs = remainingSize / avgUploadSpeed;
    }, 1000); // execute every second

    for (const uploadItem of uploadQueue) {
      await createAsset(uploadItem);
    }

    clearInterval(timer);
  }

  async function createAsset(uploadItem: UploadItem) {
    if (!createdImport || !uploadItem.file) return;

    const data = new FormData();
    data.append("asset[name]", uploadItem.file.name);
    data.append("asset[file]", uploadItem.file);
    data.append("asset[import_id]", createdImport.id.toString());

    if (uploadItem.tags) {
      uploadItem.tags.forEach((tag, idx) => {
        // Only send tags that have a value.
        if (tag.value.trim() === "") return;

        data.append(
          `asset[tags_attributes][${idx}][tag_field_id]`,
          tag.tag_field_id.toString(),
        );
        data.append(`asset[tags_attributes][${idx}][value]`, tag.value);
      });
    }

    $permissionsForm.permissions.forEach((permission, idx) => {
      data.append(
        `asset[permissions_attributes][${idx}][subject_type]`,
        "full_name" in permission.subject ? "User" : "Team",
      );

      data.append(
        `asset[permissions_attributes][${idx}][subject_id]`,
        permission.subject.id.toString(),
      );
    });

    uploadItem.status = "uploading";
    currentUpload = uploadItem;

    return uploadWithProgress(API.assets.create.path(), data, {
      onProgress: ({ delta }) => {
        totalProgressSize += delta;
      },
    })
      .then(() => {
        uploadItem.status = "uploaded";
      })
      .catch(() => {
        // TODO: send to Sentry?
        uploadItem.status = "error";
        erroredUploadCount++;
      })
      .finally(() => {
        uploadDone = !uploadQueue.some(
          (uploadItem) =>
            uploadItem.status === "pending" ||
            uploadItem.status === "uploading",
        );
      });
  }

  function openRejectedFilesDialog() {
    if (!filesByStatus.rejected) return;

    const dialog = new RejectedFilesDialog({
      target: document.body,
      props: { files: filesByStatus.rejected },
    });

    dialog.$on("destroy", () => dialog.$destroy());
  }
</script>

<Dialog.Root
  {onOpenChange}
  closeOnOutsideClick={false}
  closeOnEscape={false}
  bind:open
>
  <Dialog.Portal>
    <Dialog.Overlay class="bg-black/60 backdrop-blur-none" />

    <Dialog.Content class="block p-0 bg-zinc-100 border-none">
      <Dialog.Header class="flex-row space-y-0 bg-primary rounded-t-md">
        {#if filesByStatus.allowed}
          <Dialog.Title class="p-4 text-zinc-50 font-semibold truncate">
            Importing {filesByStatus.allowed.length}
            {filesByStatus.allowed.length === 1 ? "file" : "files"}
          </Dialog.Title>
        {:else}
          No file to import
        {/if}
        <Dialog.Close class="ms-auto">
          <XIcon />
        </Dialog.Close>
      </Dialog.Header>

      {#if uploadDone}
        <div
          class="p-4"
          transition:slide={{
            axis: "y",
            delay: 450,
            duration: 400,
            easing: cubicInOut,
          }}
        >
          {#if erroredUploadCount === uploadQueue.length}
            <div class="flex gap-2">
              <XCircleIcon class="flex-shrink-0 text-2xl text-red-500" />
              <div>
                <h1 class="pt-0.5 text-red-500 font-semibold">Error</h1>
                <p class="pt-1 text-sm">
                  All the asset uploads failed. Go to the import page by
                  clicking on the button below to find more information.
                </p>
              </div>
            </div>
          {:else if erroredUploadCount > 0}
            <div class="flex gap-2">
              <WarningCircleIcon
                class="flex-shrink-0 text-2xl text-amber-500"
              />
              <div>
                <h1 class="pt-0.5 text-amber-500 font-semibold">Warning</h1>
                <p class="pt-1 text-sm">
                  Only some assets were successfully uploaded and are now queued
                  for processing. You can follow the progress by clicking on the
                  button below.
                  <br /><br />
                  The other assets could not be uploaded. Go to the import page by
                  clicking on the button below to find more information.
                </p>
              </div>
            </div>
          {:else}
            <div class="flex gap-2">
              <CheckCircleIcon class="flex-shrink-0 text-2xl text-green-500" />
              <div>
                <h1 class="pt-0.5 text-green-500 font-semibold">Success</h1>
                <p class="pt-1 text-sm">
                  All the assets were successfully uploaded. They are now queued
                  for processing. You can follow the progress by clicking on the
                  button below.
                </p>
              </div>
            </div>
          {/if}

          <div class="flex justify-end mt-2">
            <Button class="mt-2" on:click={onOpenImportClick}>
              Open Import
            </Button>
          </div>
        </div>
      {:else if currentUpload}
        <div
          class="p-4"
          transition:slide={{
            axis: "y",
            delay: 450,
            duration: 400,
            easing: cubicInOut,
          }}
        >
          <div class="w-2/3 text-sm truncate">
            Uploading {currentUpload.file.name}
          </div>

          <Progress value={totalProgressPercent} class="mt-1" />

          <div class="flex items-center gap-1 mt-1 text-zinc-400 text-sm">
            <span>
              {prettyBytes(totalProgressSize, { maximumFractionDigits: 0 })}
              of {prettyBytes(totalSize, { maximumFractionDigits: 0 })}
            </span>
            <span>-</span>
            <span>
              {#if timeRemainingMs}
                About {prettyMs(timeRemainingMs, {
                  largest: 1,
                  units: ["h", "m", "s"],
                  round: true,
                })}
              {:else}
                Estimating time remaining...
              {/if}
            </span>
            <span class="flex items-center ms-auto">
              {totalProgressPercent}%
            </span>
          </div>
        </div>
      {:else}
        <div
          transition:slide={{ axis: "y", duration: 400, easing: cubicInOut }}
        >
          {#if filesByStatus.rejected}
            <Alert.Root
              class="w-full bg-amber-100 border-0 border-b border-amber-600 rounded-none"
            >
              <svelte:fragment slot="icon">
                <WarningCircleIcon class="w-8 h-8 text-amber-600" />
              </svelte:fragment>

              <Alert.Title class="font-bold">Heads up!</Alert.Title>
              <Alert.Description>
                {filesByStatus.rejected.length > 1
                  ? `${filesByStatus.rejected.length} files are`
                  : "A file is"} not supported and won't be imported.
              </Alert.Description>

              <svelte:fragment slot="actions">
                <Button
                  variant="ghost"
                  class="underline hover:bg-transparent hover:text-amber-600"
                  on:click={openRejectedFilesDialog}
                >
                  Show files
                </Button>
              </svelte:fragment>
            </Alert.Root>
          {/if}

          <div class="p-4">
            {#if tagFields?.length}
              <div>
                <h2 class="text-xl font-semibold leading-loose">Tags</h2>
                <span class="text-zinc-500 text-sm">
                  Assign tags to all the new assets.
                </span>
              </div>

              <TagsForm
                {tagFields}
                sectionClass="mt-4 text-sm"
                tableClass="w-full"
                bind:tags
                bind:this={tagsForm}
              />

              <Separator class="my-4 bg-zinc-300" />
            {/if}

            <div>
              <h2 class="text-xl font-semibold leading-loose">Permissions</h2>
              <span class="text-zinc-500 text-sm">
                Select who can access the new assets.
              </span>
            </div>

            <PermissionsForm form={permissionsForm} />
          </div>
        </div>

        <Dialog.Footer
          class="p-2 bg-zinc-200 border-t border-zinc-300 rounded-b-md"
        >
          <Button
            variant="secondary"
            class="hover:bg-zinc-300"
            on:click={onCancelClick}
          >
            Cancel
          </Button>
          <Button disabled={working} on:click={onImportClick}>
            {#if working}
              <Spinner />
            {:else}
              Import
            {/if}
          </Button>
        </Dialog.Footer>
      {/if}
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
