<script lang="ts" generics="ItemType">
  import { createEventDispatcher, tick } from "svelte";
  import type { Selected } from "bits-ui";

  import { cn } from "$lib/utils";

  import * as Combobox from "$components/ui/combobox";
  import * as Popover from "$components/ui/popover";
  import * as Command from "$components/ui/command";
  import { Button } from "$components/ui/button";

  import CaretUpDownIcon from "~icons/ph/caret-up-down";
  import { Check } from "svelte-radix";

  export let items: ItemType[];
  export let valueKey: keyof ItemType;
  export let labelKey: keyof ItemType;
  export let name: string;
  export let value: ItemType[typeof valueKey] | undefined = undefined;
  export let selectedItem: ItemType | undefined = undefined;
  export let placeholder: string = "Select a value";
  export let filterFn: (item: (typeof options)[number]) => boolean =
    defaultFilterFn;
  export let filterVar: keyof (typeof options)[number] = "value";
  export let editable: boolean = false;
  export let open: boolean = false;
  export let buttonClass: string | undefined = undefined;
  export let popoverContentClass: string | undefined = undefined;

  const dispatch = createEventDispatcher<{
    select: { value: typeof value; selectedItem: typeof selectedItem };
  }>();

  let options = items.map<Selected<typeof value>>((i) => {
    return {
      value: i[valueKey],
      label: i[labelKey] as string,
    };
  });
  let touchedInput = false;
  let filteredOpts: typeof options;
  let label: string | undefined = options.find((o) => o.value === value)?.label;

  $: if (value && touchedInput) {
    filteredOpts = options.filter(filterFn);
  } else {
    filteredOpts = options;
  }

  buttonClass = cn("justify-between", buttonClass);
  popoverContentClass = cn("p-0", popoverContentClass);

  function defaultFilterFn(item: (typeof options)[number]) {
    const selectValue = item[filterVar];

    if (selectValue === undefined || selectValue === null) return false;
    if (typeof value !== "string") {
      console.error(`Please define a filter function for values keyed \
by ${valueKey.toString()} (type: ${typeof valueKey}). The default filter \
function only works with strings.`);
      return false;
    }

    const valueStr: string =
      typeof selectValue === "string" ? selectValue : selectValue.toString();
    return valueStr.toLowerCase().includes(value.toLowerCase());
  }

  // We want to refocus the trigger button when the user selects
  // an item from the list so users can continue navigating the
  // rest of the form with the keyboard.
  function closeAndFocusTrigger(triggerId: string) {
    open = false;
    tick().then(() => {
      document.getElementById(triggerId)?.focus();
    });
  }

  function onSelectedChange(selected?: Selected<typeof value>) {
    if (selected !== undefined) {
      value = selected.value;
      selectedItem = items.find((i) => i[valueKey] === selected.value);
      dispatch("select", { value, selectedItem });
    }
  }
</script>

<div class="relative {$$props.class ?? ''}">
  {#if editable}
    <Combobox.Root items={filteredOpts} {onSelectedChange} bind:touchedInput>
      <Combobox.Input {placeholder} aria-label={placeholder} />
      <Combobox.Content>
        {#each filteredOpts as item (item.value)}
          <Combobox.Item value={item.value} label={item.label}>
            {item.label}
          </Combobox.Item>
        {:else}
          <span class="block px-5 py-2 text-sm text-muted-foreground">
            No results found.
          </span>
        {/each}
      </Combobox.Content>
      <Combobox.HiddenInput {name} />
    </Combobox.Root>
  {:else}
    <Popover.Root bind:open let:ids>
      <Popover.Trigger asChild let:builder>
        <Button
          builders={[builder]}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          class={buttonClass}
        >
          <span class:text-muted-foreground={!value}>
            {label || value || placeholder}
          </span>
          <CaretUpDownIcon class="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </Popover.Trigger>
      <Popover.Content class={popoverContentClass}>
        <Command.Root class="max-h-64">
          <Command.Input placeholder="Search..." />
          <Command.Empty>{placeholder}</Command.Empty>
          <Command.Group class="overflow-auto overflow-x-hidden">
            {#each options as option, idx (idx)}
              <Command.Item
                onSelect={() => {
                  value = option.value;
                  label = option.label;
                  selectedItem = items.find(
                    (i) => i[valueKey] === option.value,
                  );
                  dispatch("select", { value, selectedItem });
                  closeAndFocusTrigger(ids.trigger);
                }}
              >
                <Check
                  class={cn(
                    "mr-2 h-4 w-4",
                    value !== option.value && "text-transparent",
                  )}
                />
                {option.label}
              </Command.Item>
            {/each}
          </Command.Group>
        </Command.Root>
      </Popover.Content>
    </Popover.Root>
  {/if}
</div>
