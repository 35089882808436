<script lang="ts">
  import { onMount } from "svelte";

  import API from "$api";
  import backend, { type PaginationData } from "$lib/backend";
  import { datetime } from "$lib/utils";

  import ImportErrorsView from "$views/imports/errors/index.svelte";

  import AssetsPage from "$views/assets/_page.svelte";

  import CheckIcon from "~icons/ph/check";
  import ErrorIcon from "~icons/ph/x";
  import ProcessingIcon from "~icons/ph/hourglass-medium";
  import FailedIcon from "~icons/ph/file-x";
  import Spinner from "$components/Spinner.svelte";

  export let asset_import: Schema.Import;
  export let pagination: PaginationData;
  export let assets: Schema.Asset[];

  let refreshTimer: ReturnType<typeof setInterval>;

  let assetsByState: ReturnType<
    typeof Object.groupBy<Schema.Asset["state"], Schema.Asset>
  > = {};

  $: assetsByState = Object.groupBy(assets, (asset) => {
    return asset.state;
  });

  $: availableCount = assetsByState.available?.length ?? 0;
  $: processingCount = assetsByState.processing?.length ?? 0;
  $: failedCount = assetsByState.error?.length ?? 0;

  onMount(() => {
    if (asset_import.status === "processing") {
      subscribe();
    }
  });

  function subscribe() {
    refreshTimer = setInterval(refreshImport, 5000);
  }

  function unsubscribe() {
    clearInterval(refreshTimer);
  }

  function refreshImport() {
    backend
      .get(API.imports.show.path({ id: asset_import.id }) + ".json")
      .then(
        (newData: {
          asset_import: Schema.Import;
          assets: Schema.Asset[];
          pagination: PaginationData;
        }) => {
          if (newData.asset_import.status === "done") {
            unsubscribe();
          }

          asset_import = newData.asset_import;
          assets = newData.assets;
          pagination = newData.pagination;
        },
      );
  }

  function onViewErrorsClick() {
    const dialog = new ImportErrorsView({
      target: document.body,
      props: { errors: asset_import.error_logs ?? [] },
    });

    dialog.$on("destroy", () => dialog.$destroy());
  }
</script>

<AssetsPage {assets} {pagination}>
  <svelte:fragment slot="header">
    <div>
      <div class="flex items-center">
        <h1 class="text-3xl">
          Import of {datetime.format(asset_import.created_at)}
        </h1>

        {#if asset_import.status === "processing"}
          <span
            class="flex items-center gap-2 ms-4 py-1 px-1.5 bg-zinc-300 rounded-md text-xs uppercase font-medium"
          >
            <Spinner size="xs" />
            Processing
          </span>
        {:else}
          <span
            class="flex items-center gap-2 ms-4 py-1 px-1.5 bg-green-600 rounded-md text-white text-xs uppercase font-medium"
          >
            Done
          </span>
        {/if}
      </div>

      <div class="flex items-center gap-2 mt-1.5 text-sm">
        <CheckIcon />
        <span class="text-muted-foreground">Imported</span>
        <span class="font-medium">
          {availableCount}
          {availableCount > 1 ? "files" : "file"}
        </span>

        {#if processingCount > 0}
          <ProcessingIcon class="ml-4" />
          <span class="text-muted-foreground">Processing</span>
          <span class="font-medium">
            {processingCount}
            {processingCount > 1 ? "files" : "file"}
          </span>
        {/if}

        {#if failedCount > 0}
          <FailedIcon class="ml-4" />
          <span class="text-muted-foreground">Failed</span>
          <span class="font-medium">
            {failedCount}
            {failedCount > 1 ? "files" : "file"}
          </span>
        {/if}

        {#if asset_import.error_logs?.length}
          <ErrorIcon class="ml-4 text-red-700" />
          <span class="text-red-700 font-medium">
            {asset_import.error_logs.length}
            {asset_import.error_logs.length > 1 ? "errors" : "error"}
          </span>
          <button
            class="text-muted-foreground underline"
            on:click={onViewErrorsClick}
          >
            (view)
          </button>
        {/if}
      </div>
    </div>
  </svelte:fragment>
</AssetsPage>
