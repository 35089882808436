<script lang="ts">
  import type { PaginationData } from "$lib/backend";

  import PlusIcon from "~icons/ph/plus-bold";

  import NewUser from "$views/admin/users/new.svelte";
  import EditUser from "$views/admin/users/edit.svelte";

  import StandardPage from "$components/admin/StandardPage.svelte";
  import ListView, {
    type Column,
    type RowActions,
  } from "$components/views/ListView.svelte";
  import UserImage from "$components/UserImage.svelte";

  import * as Breadcrumb from "$components/ui/breadcrumb";
  import { Button } from "$components/ui/button";

  export let users: Schema.User[];
  export let pagination: PaginationData;

  const columns: Column<Schema.User>[] = [
    {
      name: "image",
      label: "",
      component: UserImage,
      props: (user) => ({ user }),
    },
    { name: "email", label: "E-mail" },
    { name: "first_name", label: "First name" },
    { name: "last_name", label: "Last name" },
    { name: "role_text", label: "Role" },
  ];

  const rowActions: RowActions = {
    primary: [{ label: "Edit", callback: onEditUserClick }],
  };

  function onAddUserClick() {
    const dialog = new NewUser({
      target: document.body,
    });

    dialog.$on("destroy", () => dialog.$destroy());
  }

  function onEditUserClick(idx: number) {
    const user = users[idx];
    const dialog = new EditUser({
      target: document.body,
      props: { user },
    });

    dialog.$on("destroy", () => dialog.$destroy());
  }
</script>

<StandardPage hasBackBtn>
  <svelte:fragment slot="breadcrumb">
    <Breadcrumb.Root>
      <Breadcrumb.List>
        <Breadcrumb.Item>User Management</Breadcrumb.Item>
        <Breadcrumb.Separator />
        <Breadcrumb.Item>
          <Breadcrumb.Page>Users</Breadcrumb.Page>
        </Breadcrumb.Item>
      </Breadcrumb.List>
    </Breadcrumb.Root>
  </svelte:fragment>

  <ListView
    title="Users"
    rows={users}
    {columns}
    {rowActions}
    {pagination}
    class="mt-4"
  >
    <div slot="actions">
      <Button type="button" on:click={onAddUserClick}>
        <PlusIcon class="me-1 text-xs" />
        Add user
      </Button>
    </div>

    <p slot="empty-content">
      Create the first user by clicking on the "Add user" button above.
    </p>
  </ListView>
</StandardPage>
