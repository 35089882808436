<script lang="ts">
  import { onMount, setContext } from "svelte";
  import type { Writable } from "svelte/store";
  import { slide } from "svelte/transition";
  import type { InertiaForm } from "@inertiajs/svelte";

  import API from "$api";
  import backend, { type PagedResponse } from "$lib/backend";

  import TeamMembershipForm from "$views/admin/team_memberships/_form.svelte";

  import Input from "$components/forms/Input.svelte";
  import Checkbox from "$components/forms/Checkbox.svelte";
  import AvatarInput from "$components/forms/AvatarInput.svelte";

  import { Label } from "$components/ui/label";
  import Select from "$components/forms/Select.svelte";
  import SelectItem from "$components/forms/SelectItem.svelte";

  export let form: Writable<
    InertiaForm<{
      first_name: string;
      last_name?: string;
      email: string;
      role: string;
      change_password?: boolean;
      password?: string | null;
      password_confirmation?: string | null;
      send_invitation?: boolean;
      image?: string | File;
      teams?: Schema.Team[];
      team_memberships_attributes?: Array<{ team_id: number }>;
      membershipsToAdd?: number[];
      membershipsToRemove?: number[];
    }>
  >;
  export let edit: boolean = false;

  let editPwd: boolean = false;
  let roles: Array<{ label: string; value: string }> = [];
  let teams: Schema.Team[] = [];

  setContext("form", form);

  onMount(() => {
    backend
      .get(API.adminUserRoles.index.path())
      .then((rolesArray: string[][]) => {
        roles = rolesArray.map((roleArray) => {
          return { label: roleArray[0], value: roleArray[1] };
        });
      });
    backend
      .get(API.adminTeams.index.path() + ".json?limit=9999")
      .then(({ teams: teamsArray }: PagedResponse<Schema.Team>) => {
        teams = teamsArray;
      });
  });
</script>

<div class="grid grid-cols-2 gap-6 w-full">
  <div class="flex items-center">
    <AvatarInput
      name="image"
      value={$form.image}
      accept="image/jpeg,image/png,image/webp"
      wrapperClass="flex-shrink-0"
      previewClass="w-16 h-16"
      hasPreview
    />
    <div class="flex-grow space-y-1 pl-6">
      <Label class="mb-1" required>First Name</Label>
      <Input name="first_name" />
    </div>
  </div>

  <div class="space-y-1">
    <Label class="mb-1">Last Name</Label>
    <Input name="last_name" />
  </div>

  <div class="space-y-1">
    <Label class="mb-1" required>E-mail</Label>
    <Input name="email" type="email" />
  </div>

  <div class="space-y-1">
    <Label class="mb-1" required>Role</Label>
    {#key roles}
      <Select
        name="role"
        items={roles}
        valueKey="value"
        labelKey="label"
        placeholder="Select a role"
      >
        {#each roles as role}
          <SelectItem value={role.value}>{role.label}</SelectItem>
        {/each}
      </Select>
    {/key}
  </div>

  {#if teams.length}
    <div class="col-span-2">
      <div class="flex items-center gap-2">
        <Label class="mb-1" required>Teams</Label>
        {#if $form.errors?.team_memberships}
          <span class="text-xs text-red-500 font-medium">
            {$form.errors?.team_memberships}
          </span>
        {/if}
      </div>
      <TeamMembershipForm
        existing={$form.teams ?? []}
        selection={teams}
        labelKey="name"
        valueKey="id"
        title="Team"
        bind:toAdd={$form.membershipsToAdd}
        bind:toRemove={$form.membershipsToRemove}
      />
    </div>
  {/if}

  {#if edit}
    <Checkbox
      name="change_password"
      label="Change password"
      wrapperClass="col-span-2"
      bind:checked={editPwd}
    />
  {:else}
    <Checkbox
      name="send_invitation"
      label="Send invitation by e-mail"
      wrapperClass="col-span-2"
    />
  {/if}

  {#if (!edit && !$form.send_invitation) || (edit && editPwd)}
    <div class="space-y-1" transition:slide>
      <Label class="mb-1" required>Password</Label>
      <Input name="password" type="password" />
    </div>

    <div class="space-y-1" transition:slide>
      <Label class="mb-1" required>Confirm Password</Label>
      <Input name="password_confirmation" type="password" />
    </div>
  {/if}
</div>
