// JsFromRoutes CacheKey cc193f826c61a30fb3a9a328f5fd4af5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  destroyMultiple: /* #__PURE__ */ definePathHelper('delete', '/admin/team_memberships/destroy_multiple'),
  index: /* #__PURE__ */ definePathHelper('get', '/admin/team_memberships'),
  create: /* #__PURE__ */ definePathHelper('post', '/admin/team_memberships'),
  new: /* #__PURE__ */ definePathHelper('get', '/admin/team_memberships/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/admin/team_memberships/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/team_memberships/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/team_memberships/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/admin/team_memberships/:id'),
}
