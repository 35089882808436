<script lang="ts">
  import type { HTMLInputAttributes } from "svelte/elements";

  import { cn } from "$lib/utils";

  type $$Props = HTMLInputAttributes & {
    value?: string;
    placeholder?: string;
    wrapperClass?: string;
    inputClass?: string;
    placeholderClass?: string;
    open?: boolean;
  };

  export let value: string | undefined = undefined;
  export let placeholder: string = "";
  export let wrapperClass: string | undefined = undefined;
  export let inputClass: string | undefined = undefined;
  export let placeholderClass: string | undefined = undefined;
  export let open: boolean = false;

  const dateFmt = new Intl.DateTimeFormat(["en-BE", "fr-BE"], {
    dateStyle: "medium",
  });

  let input: HTMLInputElement | undefined = undefined;

  wrapperClass = cn("relative flex items-center", wrapperClass);
  inputClass = cn("absolute invisible", inputClass);
  placeholderClass = cn("w-full", placeholderClass);

  function toggle() {
    if (open) {
      input?.blur();
      open = false;
    } else {
      input?.showPicker();
      open = true;
    }
  }
</script>

<div class={wrapperClass}>
  <input
    type="date"
    class={inputClass}
    on:change={() => (open = false)}
    bind:value
    bind:this={input}
  />
  {#if value}
    <button class={$$props.class} on:click={toggle}>
      {dateFmt.format(new Date(value))}
    </button>
  {:else}
    <button class={placeholderClass} on:click={toggle}>{placeholder}</button>
  {/if}
  <slot name="trailing" />
</div>
