<script lang="ts">
  import * as Avatar from "$components/ui/avatar";

  export let user: Schema.User;
  export let fallbackClass: string | undefined = undefined;
</script>

<Avatar.Root class={$$props.class}>
  <Avatar.Image src={user.image?.url} />
  <Avatar.Fallback class={fallbackClass}>
    {user.last_name
      ? (user.first_name[0] + user.last_name[0]).toUpperCase()
      : user.first_name.substring(0, 2).toUpperCase()}
  </Avatar.Fallback>
</Avatar.Root>
