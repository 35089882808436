<script lang="ts">
  import { onMount, setContext, type ComponentEvents } from "svelte";
  import { type Writable } from "svelte/store";
  import type { InertiaForm } from "@inertiajs/svelte";

  import API from "$api";
  import backend, { type PagedResponse } from "$lib/backend";
  import { currentUser } from "$stores/user";

  import Combobox from "$components/forms/Combobox.svelte";
  import UserImage from "$components/UserImage.svelte";
  import TeamImage from "$components/TeamImage.svelte";
  import TeamStrip from "$components/TeamStrip.svelte";

  import * as Table from "$components/ui/table";

  import RemoveIcon from "~icons/ph/x";

  export let form: Writable<
    InertiaForm<{
      permissions: Array<{
        subject: Schema.User | Schema.Team;
      }>;
    }>
  >;

  type UserOrTeam = {
    id: string;
    label: string;
    obj: Schema.User | Schema.Team;
  };

  let users: Schema.User[] | undefined = undefined;
  let teams: Schema.Team[] | undefined = undefined;
  let options: Array<UserOrTeam> = [];

  setContext("form", form);

  onMount(() => {
    backend
      .get(API.adminUsers.index.path() + ".json")
      .then((usersArray: Schema.User[]) => {
        for (const user of usersArray) {
          if (
            user.id === $currentUser?.id ||
            $form.permissions.some(
              (p) => "full_name" in p.subject && p.subject.id === user.id,
            )
          ) {
            // User is the owner of the asset or user already has a permission.
            continue;
          }

          options.push(convert(user));
        }

        options = options; // triggers UI update
        users = usersArray;
      });
    backend
      .get(API.adminTeams.index.path() + ".json?limit=9999")
      .then(({ teams: teamsArray }: PagedResponse<Schema.Team>) => {
        for (const team of teamsArray) {
          if (
            $form.permissions.some(
              (p) => "name" in p.subject && p.subject.id === team.id,
            )
          ) {
            // Team already has a permission.
            continue;
          }

          options.push(convert(team));
        }

        options = options; // triggers UI update
        teams = teamsArray;
      });
  });

  function convert(subject: Schema.User | Schema.Team): UserOrTeam {
    let id: string = "";
    let label: string = "";

    if ("full_name" in subject) {
      id = `user_${subject.id}`;
      label = subject.full_name;
    } else {
      id = `team_${subject.id}`;
      label = subject.name;
    }

    return { id, label, obj: subject };
  }

  function onSubjectSelect({
    detail,
  }: ComponentEvents<Combobox<UserOrTeam>>["select"]) {
    const selected = detail.selectedItem;

    if (selected) {
      $form.permissions = [...$form.permissions, { subject: selected.obj }];
      options = options.filter((o) => o.id !== selected.id);
    }
  }

  function remove(idx: number) {
    const removedPerms = $form.permissions.splice(idx, 1);
    options = [
      ...options,
      ...removedPerms.map<UserOrTeam>((rp) => convert(rp.subject)),
    ];
    $form.permissions = $form.permissions; // trigger UI update
  }
</script>

{#key options}
  <Combobox
    name="membership"
    items={options}
    labelKey="label"
    valueKey="id"
    filterVar="label"
    class="w-full mt-2"
    buttonClass="w-full"
    popoverContentClass="w-full"
    placeholder={users && teams
      ? "Add user or team"
      : "Loading users & teams..."}
    on:select={onSubjectSelect}
  />
{/key}

{#if $form.permissions.length}
  <Table.Root wrapperClass="mt-2 bg-white border rounded-lg">
    <Table.Body>
      {#each $form.permissions as permission, idx (idx)}
        <Table.Row>
          {#if "full_name" in permission.subject}
            <Table.Cell class="w-16 p-4">
              <UserImage user={permission.subject} />
            </Table.Cell>
            <Table.Cell>
              <span class="font-semibold">
                {permission.subject.full_name}
              </span><br />
              <span class="text-zinc-500">{permission.subject.email}</span>
            </Table.Cell>

            <Table.Cell class="text-right text-zinc-500">
              {permission.subject.role_text}
            </Table.Cell>

            <Table.Cell class="w-12 text-center">
              {#if "first_name" in permission.subject && permission.subject.id !== $currentUser?.id}
                <button
                  class="pt-1.5 text-zinc-400 hover:text-red-600"
                  on:click={() => remove(idx)}
                >
                  <RemoveIcon />
                </button>
              {/if}
            </Table.Cell>
          {:else}
            <Table.Cell class="w-16 p-4">
              <TeamImage
                team={permission.subject}
                class="flex-shrink-0"
                fallbackClass="bg-zinc-200"
              />
            </Table.Cell>
            <Table.Cell>
              <div class="flex items-center gap-2">
                <span class="font-semibold">
                  {permission.subject.name}
                </span>
                <span
                  class="py-0.5 px-1.5 bg-zinc-200 rounded text-xs text-zinc-500 font-medium"
                >
                  Team
                </span>
              </div>

              <span class="text-zinc-500">
                <TeamStrip team={permission.subject} imageClass="w-8 h-8" />
              </span>
            </Table.Cell>

            <Table.Cell class="text-right text-zinc-500">Team</Table.Cell>

            <Table.Cell class="w-12 text-center">
              <button
                class="pt-1.5 text-zinc-400 hover:text-red-600"
                on:click={() => remove(idx)}
              >
                <RemoveIcon />
              </button>
            </Table.Cell>
          {/if}
        </Table.Row>
      {/each}
    </Table.Body>
  </Table.Root>
{/if}
