<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";

  import * as Dialog from "$components/ui/dialog";
  import * as Table from "$components/ui/table";

  import XIcon from "~icons/ph/x";

  export let errors: Schema.ErrorLog[];

  const dispatch = createEventDispatcher<{ destroy: undefined }>();

  let open: boolean = false;

  onMount(() => {
    open = true;
  });

  function destroy() {
    // Emit `cancel` event after a delay to allow closing animation to finish.
    setTimeout(() => dispatch("destroy"), 400);
  }

  function onCloseClick() {
    open = false;
  }

  function onOpenChange(open: boolean) {
    if (!open) destroy();
  }
</script>

<Dialog.Root {onOpenChange} bind:open>
  <Dialog.Portal>
    <Dialog.Overlay class="bg-black/60 backdrop-blur-none" />

    <Dialog.Content
      class="block md:w-1/2 xl:w-1/4 max-w-none p-0 bg-zinc-100 border-none overflow-hidden"
    >
      <Dialog.Header class="flex-row space-y-0 bg-primary">
        <Dialog.Title class="p-4 text-zinc-50 font-semibold">
          Import errors
        </Dialog.Title>
        <button class="ms-auto me-4 text-zinc-50" on:click={onCloseClick}>
          <XIcon />
        </button>
      </Dialog.Header>

      <div class="p-4">
        <p class="text-zinc-600">The following files were not imported.</p>

        <Table.Root
          wrapperClass="max-h-64 mt-4 bg-white overflow-y-auto"
          class="h-full"
        >
          <Table.Header>
            <Table.Row>
              <Table.Head class="p-2">File</Table.Head>
              <Table.Head class="p-2">Error</Table.Head>
            </Table.Row>
          </Table.Header>
          <Table.Body class="h-full">
            {#each errors as error}
              <Table.Row>
                <Table.Cell class="p-2">{error.context.params.name}</Table.Cell>
                <Table.Cell class="p-2">{error.description}</Table.Cell>
              </Table.Row>
            {/each}
          </Table.Body>
        </Table.Root>
      </div>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
