<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { inertia } from "@inertiajs/svelte";
  import prettyBytes from "pretty-bytes";

  import API from "$api";
  import backend from "$lib/backend";
  import { applyFilters, upsertFilter } from "$stores/asset_search";

  export let assetId: number;

  $: {
    backend
      .get(API.assets.show.path({ id: assetId }) + ".json")
      .then((newAsset) => (asset = newAsset));
  }

  const dispatch = createEventDispatcher<{ destroy: undefined }>();
  const dateFmt = new Intl.DateTimeFormat(["en-BE", "fr-BE"], {
    dateStyle: "full",
    timeStyle: "short",
  });

  let asset: Schema.Asset | undefined = undefined;
  let tags: Record<string, { id: number; values: string[] }> | undefined;
  $: tags = asset?.tags?.reduce<
    Record<string, { id: number; values: string[] }>
  >((acc, tag) => {
    const tag_field_name = tag.tag_field?.name;
    const tag_field_id = tag.tag_field_id;

    if (tag_field_name && tag_field_id) {
      acc[tag_field_name] ||= { id: tag_field_id, values: [] };
      acc[tag_field_name].values.push(tag.value);
    }

    return acc;
  }, {});

  function filterOnTag(tagFieldId: number, tagValue: string) {
    upsertFilter("tags", { [tagFieldId]: tagValue });
    applyFilters();
    dispatch("destroy");
  }
</script>

{#if asset}
  <section class="text-sm">
    <header>
      <h1 class="text-sm font-semibold">Tags</h1>
    </header>
    <main>
      {#if tags}
        <table class="asset-info-table mt-2">
          <tbody>
            {#each Object.entries(tags) as [tagFieldName, tagField]}
              <tr>
                <td>{tagFieldName}</td>
                <td>
                  {#each tagField.values as tagValue, idx}
                    {#if idx > 0}
                      ,
                    {/if}
                    <button
                      class="hover:underline"
                      on:click={() => filterOnTag(tagField.id, tagValue)}
                    >
                      {tagValue}
                    </button>
                  {/each}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {:else}
        <p class="mt-2 font-light text-zinc-400">There are no tags yet.</p>
      {/if}
    </main>
  </section>

  {#if asset.file?.metadata}
    {#if asset.file.metadata.dominant_colors?.length}
      <section class="mt-4 text-sm">
        <header>
          <h1 class="text-sm font-semibold">Dominant Colors</h1>
        </header>
        <main class="flex w-full mt-2">
          {#each asset.file.metadata.dominant_colors as color}
            <span
              class="w-full h-6"
              style:background-color="rgb({color.r}, {color.g}, {color.b})"
            >
            </span>
          {/each}
        </main>
      </section>
    {/if}

    <section class="mt-4 text-sm">
      <header>
        <h1 class="font-semibold">File</h1>
      </header>
      <main>
        <table class="asset-info-table mt-2">
          <tbody>
            <tr>
              <td>Type</td>
              <td>{asset.file.metadata.mime_type}</td>
            </tr>
            {#if asset.file.metadata.width}
              <tr>
                <td>Width</td>
                <td>{asset.file.metadata.width} pixels</td>
              </tr>
            {/if}
            {#if asset.file.metadata.height}
              <tr>
                <td>Height</td>
                <td>{asset.file.metadata.height} pixels</td>
              </tr>
            {/if}
            <tr>
              <td>File size</td>
              <td>{prettyBytes(asset.file.metadata.size)}</td>
            </tr>
            {#if asset.import}
              <tr>
                <td>Import date</td>
                <td>
                  <a
                    href={API.imports.show.path({ id: asset.import.id })}
                    class="text-blue-500 underline hover:no-underline"
                    use:inertia
                  >
                    {dateFmt.format(Date.parse(asset.import.created_at))}
                  </a>
                </td>
              </tr>
            {/if}
          </tbody>
        </table>
      </main>
    </section>
  {/if}
{/if}

<style>
  .asset-info-table td {
    @apply py-0.5;
  }

  .asset-info-table td:first-child {
    @apply text-zinc-500;
  }

  .asset-info-table td:last-child {
    @apply pl-8;
  }
</style>
