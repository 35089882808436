<script lang="ts">
  import type { PaginationData } from "$lib/backend";

  import PlusIcon from "~icons/ph/plus-bold";

  import NewTeam from "$views/admin/teams/new.svelte";
  import EditTeam from "$views/admin/teams/edit.svelte";
  import ListMemberships from "$views/admin/teams/team_memberships/index.svelte";

  import StandardPage from "$components/admin/StandardPage.svelte";
  import ListView, {
    type Column,
    type RowActions,
  } from "$components/views/ListView.svelte";
  import TeamImage from "$components/TeamImage.svelte";

  import * as Breadcrumb from "$components/ui/breadcrumb";
  import { Button } from "$components/ui/button";

  export let teams: Schema.Team[];
  export let pagination: PaginationData;

  const columns: Column<Schema.Team>[] = [
    {
      name: "image",
      label: "Team",
      width: "0",
      component: TeamImage,
      props: (team) => ({ team }),
    },
    { name: "name", label: "" },
  ];

  const rowActions: RowActions = {
    primary: [{ label: "Edit", callback: onEditTeamClick }],
  };

  function onAddTeamClick() {
    const dialog = new NewTeam({
      target: document.body,
    });

    dialog.$on("destroy", () => dialog.$destroy());
  }

  function onEditTeamClick(idx: number) {
    const team = teams[idx];
    const dialog = new EditTeam({
      target: document.body,
      props: { team },
    });

    dialog.$on("destroy", () => dialog.$destroy());
  }
</script>

<StandardPage hasBackBtn>
  <svelte:fragment slot="breadcrumb">
    <Breadcrumb.Root>
      <Breadcrumb.List>
        <Breadcrumb.Item>User Management</Breadcrumb.Item>
        <Breadcrumb.Separator />
        <Breadcrumb.Item>
          <Breadcrumb.Page>Teams</Breadcrumb.Page>
        </Breadcrumb.Item>
      </Breadcrumb.List>
    </Breadcrumb.Root>
  </svelte:fragment>

  <ListView
    title="Teams"
    rows={teams}
    {columns}
    {rowActions}
    {pagination}
    class="mt-4"
  >
    <div slot="actions">
      <Button type="button" on:click={onAddTeamClick}>
        <PlusIcon class="me-1 text-xs" />
        Create Team
      </Button>
    </div>

    <p slot="empty-content">
      Create the first team by clicking on the button above.
    </p>
  </ListView>
</StandardPage>
