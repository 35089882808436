<script lang="ts">
  import type { ComponentProps } from "svelte";

  import { SelectItem } from "$components/ui/select";

  type $$Props = ComponentProps<SelectItem>;
</script>

<SelectItem {...$$props}>
  <slot />
</SelectItem>
