import { get } from "svelte/store";
import { page, router } from "@inertiajs/svelte";

import { isRecord } from "$lib/utils";

type SearchFilter<
  Attribute extends keyof Search.AssetAttributes = keyof Search.AssetAttributes,
> = {
  attribute: Attribute;
  value: Search.AssetAttributes[Attribute];
};

let filters: SearchFilter[] = [];
let query: string = "";

function initSearchEngine() {
  const currPage = get(page);
  if (!currPage) return;

  parseFromURL(currPage.url, currPage.props);
}

function parseFromURL(urlStr: string, props: any) {
  query = "";
  filters = [];

  // Parse filters from URL.
  const url = new URL(urlStr, window.location.href);
  const filtersProp = props.filters as {
    [key in Search.AssetAttribute]: Search.AssetAttributes[key];
  };

  if (filtersProp) {
    for (const attribute in filtersProp) {
      filters.push({
        attribute: attribute as Search.AssetAttribute,
        value: filtersProp[attribute as Search.AssetAttribute],
      });
    }
  }

  // Parse query from URL.
  query = url.searchParams.get("query") ?? "";
}

function upsertFilter(
  attribute: Search.AssetAttribute,
  newValue: Search.AssetAttributes[typeof attribute],
) {
  let filter = filters.find((f) => f.attribute === attribute);

  if (filter) {
    if (isRecord(filter.value) && isRecord(newValue)) {
      filter.value = { ...filter.value, ...newValue };
    } else {
      filter.value = newValue;
    }
  } else {
    filters.push({
      attribute,
      value: newValue,
    });
  }
}

function removeFilter(
  attribute: Search.AssetAttribute,
  value: Search.AssetAttributes[typeof attribute],
) {
  let idx = filters.findIndex((f) => f.attribute === attribute);

  if (idx > -1) {
    const filter = filters[idx];

    if (isRecord(filter.value) && isRecord(value)) {
      for (const key of Object.keys(value)) {
        delete filter.value[key];
      }

      if (Object.keys(filter.value).length) filters[idx] = filter;
      else filters.splice(idx, 1);
    } else {
      filters.splice(idx, 1);
    }
  }
}

function clear() {
  const currPage = get(page);
  if (!currPage) return;

  let url = new URL(currPage.url, window.location.href);

  query = "";
  filters = [];

  // Clear URL search params.
  url = new URL(url.pathname, window.location.href);

  router.visit(url);
}

function applyFilters() {
  const currPage = get(page);
  if (!currPage) return;

  let url = new URL(currPage.url, window.location.href);

  // Clear URL search params.
  url = new URL(url.pathname, window.location.href);

  if (filters.length > 0) {
    for (const filter of filters) {
      if (isRecord(filter.value)) {
        appendNestedFilter(filter, url.searchParams);
      } else {
        url.searchParams.set(
          `filter[${filter.attribute}]`,
          filter.value.toString(),
        );
      }
    }

    url.searchParams.set("query", query);
  }

  router.visit(url);
}

function getQuery(): string {
  return query;
}

function setQuery(newQuery: string) {
  const currPage = get(page);
  if (!currPage) return;

  const url = new URL(currPage.url, window.location.href);

  if (newQuery.trim() === "" && filters.length === 0) {
    url.searchParams.delete("query");
  } else {
    url.searchParams.set("query", newQuery);
  }

  query = newQuery;
  router.visit(url);
}

function appendNestedFilter(filter: SearchFilter, params: URLSearchParams) {
  for (const [key, value] of Object.entries(filter.value)) {
    params.set(`filter[${filter.attribute}][${key}]`, value.toString());
  }
}

function getFilters(): SearchFilter[] {
  return filters;
}

export {
  upsertFilter,
  removeFilter,
  applyFilters,
  clear,
  initSearchEngine,
  getQuery,
  setQuery,
  getFilters,
};
