<script lang="ts">
  import { onMount, setContext } from "svelte";
  import type { Writable } from "svelte/store";
  import type { InertiaForm } from "@inertiajs/svelte";

  import API from "$api";
  import backend from "$lib/backend";

  import Input from "$components/forms/Input.svelte";

  import { Label } from "$components/ui/label";
  import Select from "$components/forms/Select.svelte";
  import SelectItem from "$components/forms/SelectItem.svelte";

  export let form: Writable<InertiaForm<NewRecord<Schema.TagValue>>>;

  let tagFields: Schema.TagField[] = [];
  let selectedTagField: Schema.TagField | undefined;

  setContext("form", form);

  onMount(() => {
    backend
      .get(API.adminTagFields.index.path() + ".json", {
        filters: [["free_entry", "=", false]],
      })
      .then((fields: Schema.TagField[]) => {
        tagFields = fields;
      });
  });
</script>

<div class="grid grid-cols-2 gap-4 w-full">
  <div class="space-y-1">
    <Label class="mb-1" required>Tag Field</Label>
    {#if tagFields.length}
      <Select
        name="tag_field_id"
        items={tagFields}
        labelKey="name"
        valueKey="id"
        placeholder="Select a Tag Field"
        customErrorKey="tag_field"
        bind:selectedItem={selectedTagField}
      >
        {#each tagFields as tagField}
          <SelectItem value={tagField.id}>{tagField.name}</SelectItem>
        {/each}
      </Select>
    {/if}
  </div>

  <div class="space-y-1">
    <Label class="mb-1" required>Value</Label>
    <Input
      name="value"
      hint={selectedTagField && selectedTagField.format
        ? `Format: ${selectedTagField.format}`
        : undefined}
    />
  </div>
</div>
