<script lang="ts">
  import { Combobox as ComboboxPrimitive } from "bits-ui";

  import { cn } from "$lib/utils";

  type $$Props = ComboboxPrimitive.InputProps;

  export { className as class };

  let className: $$Props["class"] = undefined;

  className = cn(
    "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
    className,
  );
</script>

<ComboboxPrimitive.Input class={className} {...$$restProps} />
