<script lang="ts">
  import { createEventDispatcher } from "svelte";

  import XIcon from "~icons/ph/x-bold";

  export let tagFieldId: number;
  export let tagFieldName: string;
  export let value: string;

  const dispatch = createEventDispatcher<{ destroy: undefined }>();

  let wrapper: HTMLDivElement;

  function destroy() {
    dispatch("destroy");
  }

  function onKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case "Backspace":
      case "Delete":
        destroy();
        break;
      case "ArrowLeft":
        focusLeft();
        break;
      case "ArrowRight":
        focusRight();
        break;
      default:
        break;
    }
  }

  function focusLeft() {
    const prevSibling = wrapper.previousSibling as HTMLElement | null;
    const btn = prevSibling?.querySelector(
      "button.filter",
    ) as HTMLElement | null;

    btn?.focus();
  }

  function focusRight() {
    const nextSibling = wrapper.nextElementSibling as HTMLElement | null;
    const btn = nextSibling?.querySelector(
      "button.filter",
    ) as HTMLElement | null;
    const input = wrapper.parentElement
      ?.nextElementSibling as HTMLElement | null;

    btn ? btn.focus() : input?.focus();
  }
</script>

<div
  class="flex items-center gap-2 py-1 px-2 bg-zinc-200 focus-within:bg-blue-100 border border-zinc-300 focus-within:border-blue-300 rounded"
  bind:this={wrapper}
>
  <button
    class="filter flex items-baseline gap-2 focus:outline-none overflow-hidden"
    on:keydown={onKeydown}
  >
    <div class="text-zinc-600 text-xs uppercase font-medium">
      {tagFieldName}
    </div>
    <div class="text-zinc-900 font-semibold text-sm">
      {value}
    </div>
  </button>
  <button class="text-zinc-600 hover:text-zinc-900" on:click={destroy}>
    <XIcon class="w-3 h-3" />
  </button>
</div>
