// JsFromRoutes CacheKey b048d812591828dbaa2d8caf88f24526
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/imports'),
  create: /* #__PURE__ */ definePathHelper('post', '/imports'),
  new: /* #__PURE__ */ definePathHelper('get', '/imports/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/imports/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/imports/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/imports/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/imports/:id'),
}
