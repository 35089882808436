<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { useForm } from "@inertiajs/svelte";
  import { toast } from "svelte-sonner";

  import UserForm from "$views/admin/users/_form.svelte";

  import API from "$api";
  import backend, { type BackendError } from "$lib/backend";
  import { triggerErrorDialog } from "$lib/actions";

  import Spinner from "$components/Spinner.svelte";

  import * as Dialog from "$components/ui/dialog";
  import { Button } from "$components/ui/button";
  import * as Toaster from "$components/ui/sonner";

  import XIcon from "~icons/ph/x";

  export let user: Schema.User;

  const dispatch = createEventDispatcher<{ destroy: undefined }>();
  const form = useForm({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    role: user.role,
    change_password: false,
    password: null,
    password_confirmation: "",
    image: user.image_data?.derivatives?.thumbnail
      ? `/web/${user.image_data?.derivatives?.thumbnail.id}`
      : undefined,
    teams: user.teams,
    membershipsToAdd: [],
    membershipsToRemove: [],
  });

  let open: boolean = false;
  let saving: boolean = false;

  onMount(() => {
    open = true;
  });

  function destroy() {
    open = false;
    // Emit `cancel` event after a delay to allow closing animation to finish.
    setTimeout(() => dispatch("destroy"), 400);
  }

  function onCancelClick() {
    destroy();
  }

  function onOpenChange(open: boolean) {
    if (!open) destroy();
  }

  function onSaveClick() {
    saving = true;

    Promise.all([
      ...saveMemberships($form.membershipsToAdd, $form.membershipsToRemove),
      saveUser(),
    ])
      .then(() => {
        open = false;

        toast.success(Toaster.HTMLMessage, {
          componentProps: {
            message: `User <b>${$form.first_name}</b> was modified.`,
          },
        });
      })
      .catch((err: BackendError) => {
        if (err.message) triggerErrorDialog({ content: `${err.message}.` });
      })
      .finally(() => {
        saving = false;
      });
  }

  function saveMemberships(toAdd: number[], toRemove: number[]) {
    const promises = [];

    if (toAdd.length) {
      promises.push(
        backend.post(API.adminTeamMemberships.create.path(), {
          team_memberships: toAdd.map<{ user_id: number; team_id: number }>(
            (id) => {
              return { user_id: user.id, team_id: id };
            },
          ),
        }),
      );
    }

    if (toRemove.length) {
      promises.push(
        backend.delete(API.adminTeamMemberships.destroyMultiple.path(), {
          team_memberships: toRemove.map<{ user_id: number; team_id: number }>(
            (id) => {
              return { user_id: user.id, team_id: id };
            },
          ),
        }),
      );
    }

    return promises;
  }

  async function saveUser() {
    // Namespace the form data before sending it to the backend, as Rails
    // requires it (strong parameters).
    const railsForm = $form.transform((data: Record<string, unknown>) => {
      if (!data.change_password || !data.password) {
        delete data.password;
        delete data.password_confirmation;
      }

      // Team Membership is already dealt with.
      delete data.teams;
      delete data.membershipsToAdd;
      delete data.membershipsToRemove;

      return { user: data };
    });

    return new Promise((resolve, reject) => {
      railsForm.put(API.adminUsers.update.path({ id: user.id }), {
        onSuccess: resolve,
        onError: reject,
      });
    });
  }
</script>

<Dialog.Root {onOpenChange} bind:open>
  <Dialog.Trigger />
  <Dialog.Portal>
    <Dialog.Overlay class="bg-black/60 backdrop-blur-none" />

    <Dialog.Content
      class="block max-w-none md:w-1/2 xl:w-1/4 p-0 bg-zinc-100 border-none overflow-hidden"
    >
      <Dialog.Header class="flex-row space-y-0 bg-primary">
        <Dialog.Title class="p-4 text-zinc-50 font-semibold">
          User {user.first_name}
          {user.last_name}
        </Dialog.Title>
        <Dialog.Close class="ms-auto">
          <XIcon />
        </Dialog.Close>
      </Dialog.Header>

      <div class="py-6 px-4">
        <UserForm {form} edit />
      </div>

      <Dialog.Footer class="p-2 bg-zinc-200 border-t border-zinc-300">
        <Button
          variant="secondary"
          class="hover:bg-zinc-300"
          on:click={onCancelClick}
        >
          Cancel
        </Button>
        <Button on:click={onSaveClick} disabled={saving}>
          {#if saving}
            <Spinner />
          {:else}
            Save
          {/if}
        </Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
