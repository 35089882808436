<script lang="ts">
  import { cn } from "$lib/utils";
  import { clear } from "$stores/selection";

  export let hasFocus: boolean = false;
  export let ariaLabel: string = "";
  export let considerInside: HTMLElement | undefined = undefined;

  // Disable outside click detection.
  export function disableOCD() {
    ocdActive = false;
  }

  export function enableOCD() {
    ocdActive = true;
  }

  let element: HTMLDivElement;
  let baseClass = cn("relative inline-block", $$props.class);
  let ocdActive: boolean = true; // ocd = outside click detection

  // This function checks if the user clicked outside the Selection area.
  // The selection area is tricky to define because it might not be rectangular.
  // For example, in Masonry View, the last line might not be full width. So, if
  // the user clicks on the whitespace to the right of the last Selection Item,
  // the click should be considered outside the selection area.
  // To implement this, any click that is not on a Selection Item is considered
  // outside the selection area.
  // If the click is outside the selection area, the selection is cleared.
  function onWindowClick(event: MouseEvent) {
    if (!ocdActive) return; // Outside click detection is disabled.

    const target = event.target as HTMLElement;

    // The user clicked on a Selection Item.
    if (target.hasAttribute("data-selection-id")) return;

    // The user clicked on an area explicitely set as inside the Selection area.
    if (considerInside && considerInside.contains(target)) return;

    if (element.contains(target)) {
      // The click is inside this Selection component but not directly on a
      // Selection Item. Let's check the ascendants of target to see if we find
      // a Selection Item.
      let parent = target.parentElement;

      // Loop until we reach this Selection component.
      while (parent && parent !== element) {
        if (parent.hasAttribute("data-selection-id")) return;
        else parent = parent.parentElement;
      }
    }

    clear();
  }
</script>

<svelte:window on:click={onWindowClick} />

<div
  class={baseClass}
  role="listbox"
  aria-multiselectable="true"
  aria-label={ariaLabel}
  on:focusin={() => (hasFocus = true)}
  on:focusout={() => (hasFocus = false)}
  bind:this={element}
>
  <slot />
</div>
