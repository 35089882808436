<script lang="ts">
  import type { HTMLAttributes } from "svelte/elements";
  import { type Variant, alertVariants } from "./index.js";
  import { cn } from "$lib/utils.js";

  type $$Props = HTMLAttributes<HTMLDivElement> & {
    variant?: Variant;
  };

  let className: $$Props["class"] = undefined;
  export let variant: $$Props["variant"] = "default";
  export { className as class };
</script>

<div
  class={cn(alertVariants({ variant }), className)}
  {...$$restProps}
  role="alert"
>
  <slot name="icon" />

  <div class="flex-grow" class:pl-4={$$slots.icon} class:pr-4={$$slots.actions}>
    <slot />
  </div>

  <slot name="actions" />
</div>
