<script lang="ts">
  import { router } from "@inertiajs/svelte";

  import API from "$api";
  import backend, { type PaginationData } from "$lib/backend";

  import NewTagField from "$views/admin/tag_fields/new.svelte";
  import EditTagField from "$views/admin/tag_fields/edit.svelte";

  import StandardPage from "$components/admin/StandardPage.svelte";
  import ListView, {
    type Column,
    type RowActions,
  } from "$components/views/ListView.svelte";
  import { triggerConfirmation } from "$components/ConfirmationDialog.svelte";

  import * as Breadcrumb from "$components/ui/breadcrumb";
  import { Button } from "$components/ui/button";

  import PlusIcon from "~icons/ph/plus-bold";
  import { datetime } from "$lib/utils";

  export let fields: Schema.TagField[];
  export let pagination: PaginationData;

  const columns: Column<Schema.TagField>[] = [
    { name: "name", label: "Name" },
    { name: "taggable_type", label: "Applicable to" },
    {
      name: "required",
      label: "Required",
      format: (required: boolean) => (required ? "Yes" : "No"),
    },
    {
      name: "free_entry",
      label: "Free entry",
      format: (free_entry: boolean) => (free_entry ? "Yes" : "No"),
    },
    {
      name: "created_at",
      label: "Creation date",
      format: (created_at: string) =>
        datetime.format(created_at, { dateStyle: "long" }),
    },
    {
      name: "updated_at",
      label: "Last update date",
      format: (updated_at: string) =>
        datetime.format(updated_at, { dateStyle: "long" }),
    },
  ];

  const rowActions: RowActions = {
    primary: [{ label: "Edit", callback: onEditTagFieldClick }],
    secondary: [{ label: "Delete", callback: onDeleteTagFieldClick }],
  };

  function onAddTagFieldClick() {
    const dialog = new NewTagField({
      target: document.body,
    });

    dialog.$on("destroy", () => dialog.$destroy());
  }

  function onEditTagFieldClick(idx: number) {
    const tag_field = fields[idx];
    const dialog = new EditTagField({
      target: document.body,
      props: { tag_field },
    });

    dialog.$on("destroy", () => dialog.$destroy());
  }

  function onDeleteTagFieldClick(idx: number) {
    const tag_field = fields[idx];

    triggerConfirmation({
      title: "Please confirm action",
      description: `Are you sure you want to delete Tag Field <b>${tag_field.name}</b>?`,
      confirmBtnLabel: "Yes, I'm sure",
    }).then(({ confirmed }) => {
      if (confirmed) {
        backend
          .delete(API.adminTagFields.destroy.path({ id: tag_field.id }))
          .finally(() => router.reload());
      }
    });
  }
</script>

<StandardPage hasBackBtn>
  <svelte:fragment slot="breadcrumb">
    <Breadcrumb.Root>
      <Breadcrumb.List>
        <Breadcrumb.Item>Tag Management</Breadcrumb.Item>
        <Breadcrumb.Separator />
        <Breadcrumb.Item>
          <Breadcrumb.Page>Tag Fields</Breadcrumb.Page>
        </Breadcrumb.Item>
      </Breadcrumb.List>
    </Breadcrumb.Root>
  </svelte:fragment>

  <ListView
    title="Tag Fields"
    rows={fields}
    {columns}
    {rowActions}
    {pagination}
    class="mt-4"
  >
    <div slot="actions">
      <Button type="button" on:click={onAddTagFieldClick}>
        <PlusIcon class="me-1 text-xs" />
        New Tag Field
      </Button>
    </div>

    <p slot="empty-content">
      Create the first tag by clicking on the "Add tag" button above.
    </p>
  </ListView>
</StandardPage>
