// JsFromRoutes CacheKey 16ed2b2107f473b208caac29fec24e0a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  batchUpdate: /* #__PURE__ */ definePathHelper('put', '/assets/batch_update'),
  upsert: /* #__PURE__ */ definePathHelper('put', '/assets/:asset_id/tags/upsert'),
  index: /* #__PURE__ */ definePathHelper('get', '/assets/:asset_id/tags'),
}
