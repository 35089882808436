<script lang="ts">
  import type { HTMLAttributes } from "svelte/elements";
  import { cn } from "$lib/utils.js";

  type $$Props = HTMLAttributes<HTMLTableSectionElement>;

  let className: $$Props["class"] = undefined;
  export { className as class };
</script>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<thead
  class={cn("bg-gradient-to-t from-zinc-200/80 to-zinc-100", className)}
  {...$$restProps}
  on:click
  on:keydown
>
  <slot />
</thead>
