<script lang="ts">
  import { Combobox as ComboboxPrimitive } from "bits-ui";
  import Check from "svelte-radix/Check.svelte";

  import { cn } from "$lib/utils.js";

  type $$Props = ComboboxPrimitive.ItemProps;
  type $$Events = Required<ComboboxPrimitive.ItemEvents>;

  export let value: $$Props["value"];
  export let label: $$Props["label"] = undefined;
  export let disabled: $$Props["disabled"] = undefined;
  export { className as class };

  let className: $$Props["class"] = undefined;

  className = cn(
    "relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50",
    className,
  );
</script>

<ComboboxPrimitive.Item
  {value}
  {disabled}
  {label}
  class={className}
  {...$$restProps}
  on:click
  on:pointermove
  on:focusin
>
  <span class="absolute right-2 flex h-3.5 w-3.5 items-center justify-center">
    <ComboboxPrimitive.ItemIndicator>
      <Check class="h-4 w-4" />
    </ComboboxPrimitive.ItemIndicator>
  </span>
  <slot>
    {label || value}
  </slot>
</ComboboxPrimitive.Item>
