<script lang="ts">
  import { Pagination as PaginationPrimitive } from "bits-ui";
  import { router } from "@inertiajs/svelte";

  import { cn } from "$lib/utils.js";

  type $$Props = PaginationPrimitive.Props;
  type $$Events = PaginationPrimitive.Events;

  let className: $$Props["class"] = undefined;
  export let count: $$Props["count"] = 0;
  export let perPage: $$Props["perPage"] = 10;
  export let page: $$Props["page"] = 1;
  export let siblingCount: $$Props["siblingCount"] = 1;
  export let onPageChange: $$Props["onPageChange"] = (newPage: number) => {
    const pageUrl = new URL(window.location.href);
    pageUrl.searchParams.set("page", newPage.toString());
    router.visit(pageUrl);
  };

  export { className as class };

  $: currentPage = page;
</script>

<PaginationPrimitive.Root
  {count}
  {perPage}
  {siblingCount}
  {onPageChange}
  bind:page
  let:builder
  let:pages
  let:range
  asChild
  {...$$restProps}
>
  <nav
    {...builder}
    class={cn("mx-auto flex w-full flex-col items-center", className)}
  >
    <slot {pages} {range} {currentPage} />
  </nav>
</PaginationPrimitive.Root>
