<script lang="ts">
  import { cn } from "$lib/utils";
  import UserImage from "./UserImage.svelte";

  export let team: Schema.Team;
  export let count: number = 5;
  export let imageClass: string | undefined = undefined;

  imageClass = cn(
    "-translate-x-1/2 first:translate-x-0 border-2 border-white shadow-xs",
    imageClass,
  );
</script>

{#if team.members}
  <div class="flex items-center">
    {#each team.members as member, idx}
      {#if idx < count}
        <UserImage user={member} class={imageClass} />
      {/if}
    {/each}
  </div>
{/if}
