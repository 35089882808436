<script lang="ts" generics="ItemType">
  import {
    createEventDispatcher,
    getContext,
    hasContext,
    onMount,
    type ComponentProps,
  } from "svelte";
  import { get } from "svelte/store";
  import type { Selected } from "bits-ui";

  import * as Select from "$components/ui/select";
  import { cn } from "$lib/utils";

  export let name: string | undefined = undefined;
  export let value:
    | ItemType[typeof valueKey]
    | Array<ItemType[typeof valueKey]>
    | undefined = undefined;
  export let items: ItemType[];
  export let valueKey: keyof ItemType;
  export let labelKey: keyof ItemType;
  export let selectedItem: ItemType | ItemType[] | undefined = undefined;
  export let multiple: boolean = false;
  export let placeholder: string = multiple
    ? "Select one or more values"
    : "Select a value";

  export let wrapperClass: string | undefined = undefined;
  export let triggerClass: string | undefined = undefined;
  export let valueClass: string | undefined = undefined;
  export let contentClass: string | undefined = undefined;
  export let alignContent: ComponentProps<Select.Content>["align"] | undefined =
    undefined;
  export let customErrorKey: string | undefined = undefined;

  export let onSelectedChange = function (
    newSelected?:
      | Selected<ItemType[typeof valueKey]>
      | Array<Selected<ItemType[typeof valueKey]>>,
  ) {
    if (newSelected !== undefined) {
      if (Array.isArray(newSelected)) {
        selectedItem = [];
        for (const ns of newSelected) {
          const item = items.find((i) => i[valueKey] === ns.value);
          if (item) selectedItem.push(item);
        }

        value = newSelected
          .filter((ns) => ns !== undefined)
          .map((ns) => ns.value);
      } else if (newSelected.value !== value) {
        selectedItem = items.find((i) => i[valueKey] === newSelected.value);
        value = newSelected.value;
      }

      selected = newSelected;
      updateForm(value);
      dispatch("select", { value, selectedItem });
    }
  };

  const dispatch = createEventDispatcher<{
    select: { value: typeof value; selectedItem: typeof selectedItem };
  }>();

  let form: FormContext | undefined = undefined;
  let error: string | undefined = undefined;

  let selected:
    | Selected<ItemType[typeof valueKey]>
    | Array<Selected<ItemType[typeof valueKey]>>
    | undefined = undefined;
  let options = items.map<Selected<ItemType[typeof valueKey]>>((i) => {
    return {
      value: i[valueKey],
      label: i[labelKey] as string,
    };
  });

  wrapperClass = cn(wrapperClass);
  triggerClass = cn("py-2 px-3 rounded-md shadow-btn", triggerClass);
  valueClass = cn(
    "block text-base data-[placeholder]:text-zinc-400 data-[placeholder]:italic data-[placeholder]:font-light",
    valueClass,
  );

  if (name && hasContext("form")) {
    form = getContext<FormContext>("form");

    form.subscribe((newRecord) => {
      error = newRecord.errors[customErrorKey ?? name];
    });

    value = get(form).data()[name];
  }

  onMount(() => {
    if (value) {
      // Init `selected` and `selectedItem` if a value is given.

      if (Array.isArray(value)) {
        // When `multiple` options are selectable.

        const newSelected: Array<Selected<ItemType[typeof valueKey]>> = [];
        for (const v of value) {
          const option = options.find((o) => o.value === v);
          if (option) newSelected.push(option);
        }

        const newItems: ItemType[] = [];
        for (const v of value) {
          const item = items.find((i) => i[valueKey] === v);
          if (item) newItems.push(item);
        }

        selected = newSelected;
        selectedItem = newItems;
      } else {
        selected = options.find((s) => s.value === value);
        selectedItem = items.find((i) => i[valueKey] === value);
      }
    }
  });

  function updateForm(newValue: typeof value) {
    if (name && form && name in get(form).data()) {
      form.update((curr) => {
        return { ...curr, [name]: newValue };
      });
    }
  }
</script>

<div class="select {wrapperClass}">
  <Select.Root items={options} {name} {selected} {multiple} {onSelectedChange}>
    <Select.Trigger class={triggerClass}>
      {#if $$slots.trigger}
        <slot name="trigger" {value} {selectedItem} />
      {:else}
        <Select.Value class={valueClass} {placeholder} />
      {/if}
    </Select.Trigger>
    <Select.Content align={alignContent} class={contentClass}>
      <slot />
    </Select.Content>
  </Select.Root>

  {#if error}
    <div class="flex gap-1 mt-1">
      <span class="text-xs text-red-500 font-medium">{error}</span>
    </div>
  {/if}
</div>

<style>
  .select > :global(.shadow-btn:not(:focus-within)) {
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.5);
  }
</style>
