import { Combobox as ComboboxPrimitive } from "bits-ui";

import Content from "./combobox-content.svelte";
import Input from "./combobox-input.svelte";
import Item from "./combobox-item.svelte";
import Separator from "./combobox-separator.svelte";

const Arrow = ComboboxPrimitive.Arrow;
const HiddenInput = ComboboxPrimitive.HiddenInput;
const ItemIndicator = ComboboxPrimitive.ItemIndicator;
const Root = ComboboxPrimitive.Root;

export {
  Arrow,
  Content,
  HiddenInput,
  Input,
  Item,
  ItemIndicator,
  Root,
  Separator,
  //
  Arrow as ComboboxArrow,
  Content as ComboboxContent,
  HiddenInput as ComboboxHiddenInput,
  Input as ComboboxInput,
  Item as ComboboxItem,
  ItemIndicator as ComboboxItemIndicator,
  Root as Combobox,
  Separator as ComboboxSeparator,
};
