<script lang="ts">
  import { AlertDialog as AlertDialogPrimitive } from "bits-ui";
  import {
    type Props as ButtonProps,
    buttonVariants
  } from "$components/ui/button/index.js";
  import { cn } from "$lib/utils.js";

  export let variant: ButtonProps["variant"] = "default";
  export let size: ButtonProps["size"] = "default";

  let className = $$props.class;
</script>

<AlertDialogPrimitive.Action
  class={cn(buttonVariants({ variant, size, className }))}
  {...$$restProps}
  on:click
  on:keydown
  let:builder
>
  <slot {builder} />
</AlertDialogPrimitive.Action>
