<script lang="ts">
  import type { HTMLAnchorAttributes } from "svelte/elements";
  import { inertia, page } from "@inertiajs/svelte";

  type $$Props = HTMLAnchorAttributes & { href: string };

  export let href: string;
  export let active: boolean = false;

  $: active = $page?.url.startsWith(href) ?? false;
</script>

<a {href} {...$$restProps} class:active use:inertia>
  <slot />
</a>
