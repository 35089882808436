<script lang="ts">
  import { cn } from "$lib/utils";

  let wrapperClass = cn(
    "flex flex-wrap w-full after:content-[''] after:flex-grow-[9999]",
    $$props.class,
  );
</script>

<section class={wrapperClass}>
  <slot />
</section>
