<script lang="ts">
  import { getContext, hasContext } from "svelte";
  import { get } from "svelte/store";

  import { cn } from "$lib/utils";

  import { Checkbox } from "$components/ui/checkbox";
  import { Label } from "$components/ui/label";

  export let name: string;
  export let label: string | null = null;
  export let checked: boolean = false;
  export let hint: string | null = null;
  export let wrapperClass: string | undefined = undefined;
  export let disabled: boolean | undefined = undefined;
  let checkboxClass: string | undefined = undefined;
  export { checkboxClass as class };

  let form: FormContext | undefined = undefined;
  let error: string | undefined = undefined;

  if (hasContext("form")) {
    form = getContext<FormContext>("form");

    form.subscribe((newRecord) => {
      error = newRecord.errors[name];
    });

    const initialValue = get(form).data()[name];
    checked = initialValue as boolean;
  }

  wrapperClass = cn("flex gap-2", wrapperClass);
  checkboxClass = cn("mt-1", checkboxClass);

  function onCheckedChange(checked: boolean | "indeterminate") {
    if (checked === "indeterminate") checked = false;

    if (form && name in get(form).data()) {
      form.update((curr) => {
        return { ...curr, [name]: checked };
      });
    }
  }
</script>

<div class={wrapperClass}>
  <Checkbox
    id={name}
    class={checkboxClass}
    {onCheckedChange}
    {disabled}
    bind:checked
  />

  {#if label}
    <div>
      <Label
        for={name}
        class="font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </Label>

      {#if hint}
        <span class="block text-xs text-zinc-500">{hint}</span>
      {/if}
    </div>
  {/if}
</div>
