import { showDirectoryPicker } from "$lib/polyfills";
import { pickFiles } from "$lib/actions";

import NewImport from "$views/imports/new.svelte";
import type { ComponentProps } from "svelte";

export type ImportItem = {
  id: number;
  asset: Schema.Asset;
  progress: number;
  errors?: string[];
};

export async function importFiles() {
  // For smoother UX, pre-mount the import dialog but keep it closed.
  const importDialog = newImportDialog();

  try {
    const fileHandles = await pickFiles({ multiple: true });
    const files = await Promise.all(
      fileHandles.map((fileHandle) => fileHandle.getFile()),
    );

    importDialog.$set({ files, open: true });
  } catch (err) {
    // User cancelled file picker.
    if (err instanceof DOMException && err.name === "AbortError") {
      return importDialog.$destroy();
    }
  }
}

export async function importFolders() {
  // For smoother UX, pre-mount the import dialog but keep it closed.
  const importDialog = newImportDialog();

  const files = await showDirectoryPicker();

  if (files === null) {
    // User cancelled or no files in selected folder.
    return importDialog.$destroy();
  }

  importDialog.$set({ files, open: true });
}

export function newImportDialog(props?: ComponentProps<NewImport>): NewImport {
  const dialog = new NewImport({
    target: document.body,
    props: { ...props },
  });

  dialog.$on("destroy", () => dialog.$destroy());

  return dialog;
}
