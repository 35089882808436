<script lang="ts">
  import { cn } from "$lib/utils.js";

  export let title: string | undefined = undefined;
  export let wrapperClass: string | null = null;
  export let embedded: boolean = false;

  let tableClass = cn(
    "w-full caption-bottom [&_thead_tr]:border-b [&_thead_tr]:border-zinc-300 [&_tfoot_tr]:ring-1 [&_tfoot_tr]:ring-zinc-300 [&_tbody_tr:hover]:bg-muted/50",
    title && "[&_thead_tr]:border-t",
    $$props.class,
  );

  wrapperClass = cn(
    "overflow-hidden text-sm",
    embedded ? "" : "border border-zinc-300 rounded",
    wrapperClass,
  );
</script>

<div class={wrapperClass}>
  <table class={tableClass}>
    <slot />
  </table>
</div>
