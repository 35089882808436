<script lang="ts">
  import { Slider as SliderPrimitive } from "bits-ui";
  import { cn } from "$lib/utils.js";
  import { createEventDispatcher, onMount } from "svelte";

  type $$Props = SliderPrimitive.Props;

  let className: $$Props["class"] = undefined;
  export let value: $$Props["value"] = [0];
  export { className as class };

  const dispatch = createEventDispatcher<{ valueCommitted: typeof value }>();

  let wrapper: HTMLSpanElement;
  let thumbElement: HTMLSpanElement;

  onMount(() => {
    // onValueCommitted is not implemented in bits-ui yet. Here is a temporary
    // fix.
    wrapper.addEventListener("click", onValueCommitted);
    thumbElement.addEventListener("click", onValueCommitted);
  });

  function onValueCommitted() {
    dispatch("valueCommitted", value);
  }
</script>

<SliderPrimitive.Root
  bind:value
  class={cn("relative flex w-full items-center", className)}
  {...$$restProps}
  let:thumbs
  bind:el={wrapper}
>
  <span
    class="relative h-1.5 w-full grow overflow-hidden rounded-full bg-primary/20"
  >
    <SliderPrimitive.Range class="absolute h-full bg-primary" />
  </span>
  {#each thumbs as thumb}
    <SliderPrimitive.Thumb
      {thumb}
      class="block h-4 w-4 rounded-full border border-primary/50 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
      bind:el={thumbElement}
    />
  {/each}
</SliderPrimitive.Root>
