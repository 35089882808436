<script context="module" lang="ts">
  export type Row = { value?: number; label: string };
</script>

<script lang="ts" generics="MembershipType">
  import { createEventDispatcher } from "svelte";

  import Combobox from "$components/forms/Combobox.svelte";

  import * as Table from "$components/ui/table";
  import { Checkbox } from "$components/ui/checkbox";

  import DeleteIcon from "~icons/ph/trash";

  export let item: MembershipType | undefined;
  export let items: MembershipType[];
  export let labelKey: keyof MembershipType;
  export let valueKey: keyof MembershipType;

  const dispatch = createEventDispatcher<{
    delete: { item: typeof item };
  }>();
</script>

<Table.Row>
  <Table.Cell class="py-2 pl-4">
    <Checkbox />
  </Table.Cell>
  <Table.Cell class="px-3">
    {#if item}
      {item[labelKey]}
    {:else}
      <Combobox
        name="membership"
        {items}
        {labelKey}
        {valueKey}
        filterVar="label"
        class="w-full"
        buttonClass="w-full"
        popoverContentClass="w-full"
        on:select
        bind:selectedItem={item}
      />
    {/if}
  </Table.Cell>
  <Table.Cell class="pr-4 text-right">
    <button
      type="button"
      class="mt-1 text-zinc-400 hover:text-red-600"
      on:click={() => dispatch("delete", { item })}
    >
      <DeleteIcon />
    </button>
  </Table.Cell>
</Table.Row>
