<script lang="ts">
  import type { HTMLThAttributes } from "svelte/elements";
  import { cn } from "$lib/utils.js";

  type $$Props = HTMLThAttributes;

  let className: $$Props["class"] = undefined;
  export { className as class };
</script>

<th
  class={cn(
    "text-left align-middle font-medium text-muted-foreground",
    className,
  )}
  {...$$restProps}
>
  <slot />
</th>
