<script lang="ts">
  import { Select as SelectPrimitive } from "bits-ui";
  import CaretSort from "svelte-radix/CaretSort.svelte";
  import { cn } from "$lib/utils.js";

  type $$Props = SelectPrimitive.TriggerProps;
  type $$Events = SelectPrimitive.TriggerEvents;

  let className: $$Props["class"] = undefined;
  export { className as class };
</script>

<SelectPrimitive.Trigger
  class={cn(
    "flex w-full items-center justify-between whitespace-nowrap rounded-md border border-input focus:border-primary bg-white p-1 text-sm placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1",
    className,
  )}
  {...$$restProps}
>
  <slot />
  <div>
    <CaretSort class="h-4 w-4 opacity-50" />
  </div>
</SelectPrimitive.Trigger>
