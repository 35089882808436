<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";

  import API from "$api";
  import backend, { type BackendError } from "$lib/backend";
  import { triggerErrorDialog } from "$lib/actions";

  import TeamMembershipsForm from "$views/admin/team_memberships/_form.svelte";

  import * as Dialog from "$components/ui/dialog";
  import { Button } from "$components/ui/button";

  import XIcon from "~icons/ph/x";

  export let team: Schema.Team;

  const dispatch = createEventDispatcher<{ destroy: undefined }>();

  let open: boolean = false;
  let saving: boolean = false;

  let users: Schema.User[] | undefined = undefined;
  let toAdd: number[] = [];
  let toRemove: number[] = [];

  onMount(() => {
    backend
      .get(API.adminUsers.index.path() + ".json")
      .then((usersArray: Schema.User[]) => {
        users = usersArray;
      });

    open = true;
  });

  function destroy() {
    open = false;
    // Emit `cancel` event after a delay to allow closing animation to finish.
    setTimeout(() => dispatch("destroy"), 400);
  }

  function onCancelClick() {
    destroy();
  }

  function onOpenChange(open: boolean) {
    if (!open) destroy();
  }

  function onSaveClick() {
    saving = true;

    const promises = [];

    if (toAdd.length) {
      promises.push(
        backend.post(API.adminTeamMemberships.create.path(), {
          team_memberships: toAdd.map<{ user_id: number; team_id: number }>(
            (id) => {
              return { user_id: id, team_id: team.id };
            },
          ),
        }),
      );
    }

    if (toRemove.length) {
      promises.push(
        backend.delete(API.adminTeamMemberships.destroyMultiple.path(), {
          team_memberships: toRemove.map<{ user_id: number; team_id: number }>(
            (id) => {
              return { user_id: id, team_id: team.id };
            },
          ),
        }),
      );
    }

    Promise.all(promises)
      .then(() => {
        open = false;
      })
      .catch((err: BackendError) => {
        triggerErrorDialog({ content: `${err.message}.` });
      })
      .finally(() => {
        saving = false;
      });
  }
</script>

<Dialog.Root {onOpenChange} {open}>
  <Dialog.Portal>
    <Dialog.Overlay class="bg-black/60 backdrop-blur-none" />

    <Dialog.Content class="block p-0 bg-zinc-100 border-none overflow-hidden">
      <Dialog.Header class="flex-row space-y-0 bg-primary">
        <Dialog.Title class="p-4 text-zinc-50 font-semibold">
          Team {team.name}
        </Dialog.Title>
        <Dialog.Close class="ms-auto">
          <XIcon />
        </Dialog.Close>
      </Dialog.Header>

      <div class="py-6 px-4">
        <p class="text-zinc-700">Here is the list of members on this team:</p>

        {#if users && team.members}
          <TeamMembershipsForm
            existing={team.members}
            selection={users}
            labelKey="full_name"
            valueKey="id"
            title="User"
            bind:toAdd
            bind:toRemove
          />
        {/if}
      </div>

      <Dialog.Footer class="p-2 bg-zinc-200 border-t border-zinc-300">
        <Button
          variant="secondary"
          class="hover:bg-zinc-300"
          on:click={onCancelClick}
        >
          Cancel
        </Button>
        <Button on:click={onSaveClick}>Save</Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
