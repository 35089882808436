<script lang="ts">
  import { Progress as ProgressPrimitive } from "bits-ui";
  import { cn } from "$lib/utils.js";

  type $$Props = ProgressPrimitive.Props & {
    foregroundClass?: typeof foregroundClass;
  };

  let className: $$Props["class"] = undefined;
  export let max: $$Props["max"] = undefined;
  export let value: $$Props["value"] = undefined;
  export { className as class };

  export let foregroundClass: string | undefined = undefined;

  value ??= 0;
  max ??= 100;

  $: backgroundClass = cn(
    "relative h-2 w-full overflow-hidden rounded-full bg-primary/20",
    className,
  );

  $: foregroundClass = cn(
    "h-full flex-1 bg-primary transition-all ease-in-out duration-200",
    foregroundClass,
  );
</script>

<ProgressPrimitive.Root class={backgroundClass} {...$$restProps}>
  <div class={foregroundClass} style:width={`${(100 * value) / max}%`}></div>
</ProgressPrimitive.Root>
