<script lang="ts">
  import { cn } from "$lib/utils";

  export let title: string | null = null;

  let wrapperClasses = cn(
    "flex flex-col items-center justify-center w-full aspect-video",
    $$props.class,
  );
</script>

<div class={wrapperClasses}>
  {#if title}
    <h1 class="text-3xl font-semibold leading-loose">{title}</h1>
  {/if}

  <p class="text-zinc-500">
    <slot />
  </p>
</div>
