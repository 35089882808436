<script lang="ts">
  import { type PaginationData } from "$lib/backend";

  import StandardPage from "$components/admin/StandardPage.svelte";
  import ListView, {
    type Column,
    type RowActions,
  } from "$components/views/ListView.svelte";

  import * as Breadcrumb from "$components/ui/breadcrumb";

  import { datetime } from "$lib/utils";

  export let downloads: Schema.Download[];
  export let pagination: PaginationData;

  const columns: Column<Schema.Download>[] = [
    {
      name: "user",
      label: "User",
      format: (user?: Schema.User) => user?.full_name ?? "",
    },
    {
      name: "downloadable_type",
      label: "Downloaded Object",
      format: (_: any, download: Schema.Download) => {
        return `${download.downloadable_type}`;
      },
    },
    {
      name: "created_at",
      label: "Start Time",
      format: (createdAt: string) =>
        datetime.format(createdAt, { dateStyle: "medium", timeStyle: "short" }),
    },
  ];

  const rowActions: RowActions = {};
</script>

<StandardPage hasBackBtn>
  <svelte:fragment slot="breadcrumb">
    <Breadcrumb.Root>
      <Breadcrumb.List>
        <Breadcrumb.Item>Asset Management</Breadcrumb.Item>
        <Breadcrumb.Separator />
        <Breadcrumb.Item>
          <Breadcrumb.Page>Downloads</Breadcrumb.Page>
        </Breadcrumb.Item>
      </Breadcrumb.List>
    </Breadcrumb.Root>
  </svelte:fragment>

  <ListView
    title="Downloads"
    rows={downloads}
    {columns}
    {rowActions}
    {pagination}
    class="mt-4"
  >
    <p slot="empty-content">There are no downloads yet.</p>
  </ListView>
</StandardPage>
