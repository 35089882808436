<script lang="ts">
  import { router } from "@inertiajs/svelte";

  import API from "$api";
  import backend, { type PaginationData } from "$lib/backend";

  import NewTagValue from "$views/admin/tag_values/new.svelte";
  import EditTagValue from "$views/admin/tag_values/edit.svelte";

  import StandardPage from "$components/admin/StandardPage.svelte";
  import ListView, {
    type Column,
    type RowActions,
  } from "$components/views/ListView.svelte";
  import { triggerConfirmation } from "$components/ConfirmationDialog.svelte";

  import * as Breadcrumb from "$components/ui/breadcrumb";
  import { Button } from "$components/ui/button";

  import PlusIcon from "~icons/ph/plus-bold";

  export let tag_values: Schema.TagValue[];
  export let pagination: PaginationData;

  const columns: Column<Schema.TagValue>[] = [
    {
      name: "tag_field",
      label: "Tag Field",
      format(tagField: Schema.TagField) {
        return tagField.name;
      },
    },
    { name: "value", label: "Value" },
  ];

  const rowActions: RowActions = {
    primary: [{ label: "Edit", callback: onEditTagValueClick }],
    secondary: [{ label: "Delete", callback: onDeleteTagValueClick }],
  };

  function onAddTagValueClick() {
    const dialog = new NewTagValue({
      target: document.body,
    });

    dialog.$on("destroy", () => dialog.$destroy());
  }

  function onEditTagValueClick(idx: number) {
    const tag_value = tag_values[idx];
    const dialog = new EditTagValue({
      target: document.body,
      props: { tag_value },
    });

    dialog.$on("destroy", () => dialog.$destroy());
  }

  function onDeleteTagValueClick(idx: number) {
    const tag_value = tag_values[idx];

    triggerConfirmation({
      title: "Please confirm action",
      description: `Are you sure you want to delete value <b>${tag_value.value}</b>?`,
      confirmBtnLabel: "Yes, I'm sure",
    }).then(({ confirmed }) => {
      if (confirmed) {
        backend
          .delete(API.adminTagValues.destroy.path({ id: tag_value.id }))
          .finally(() => router.reload());
      }
    });
  }
</script>

<StandardPage hasBackBtn>
  <svelte:fragment slot="breadcrumb">
    <Breadcrumb.Root>
      <Breadcrumb.List>
        <Breadcrumb.Item>Tag Management</Breadcrumb.Item>
        <Breadcrumb.Separator />
        <Breadcrumb.Item>
          <Breadcrumb.Page>Predefined Tag Values</Breadcrumb.Page>
        </Breadcrumb.Item>
      </Breadcrumb.List>
    </Breadcrumb.Root>
  </svelte:fragment>

  <ListView
    title="Predefined Tag Values"
    rows={tag_values}
    {columns}
    {rowActions}
    {pagination}
    class="mt-4"
  >
    <div slot="actions">
      <Button type="button" on:click={onAddTagValueClick}>
        <PlusIcon class="me-1 text-xs" />
        New Value
      </Button>
    </div>

    <p slot="empty-content">
      Create the first predefined tag value by clicking on the button above.
    </p>
  </ListView>
</StandardPage>
