import { isObjectRecord } from "$lib/utils";
import { toast } from "svelte-sonner";

import API from "$api";

import { HTMLMessage } from "$components/ui/sonner";

type SearchParamsTypes =
  | string[][]
  | Record<string, string>
  | string
  | URLSearchParams;

type PaginationData = {
  first_url: string;
  prev_url: string;
  page_url: string;
  next_url: string;
  last_url: string;
  page: number;
  count: number;
  limit: number;
  prev: number | null;
  next: number | null;
  last: number;
  in: number;
  offset: number;
};

type Response<T> = {
  [key: string]: T[];
};

type PagedResponse<T> = Response<T> & {
  pagination: PaginationData;
};

class BackendError extends Error {
  status: number;
  data?: unknown & { errors?: string[] };

  constructor({
    status,
    data,
  }: {
    status: number;
    data?: unknown & { errors?: string[] };
  }) {
    super();
    this.name = "BackendError";
    this.status = status;
    this.data = data;
    this.message = data?.errors?.length ? data.errors[0] : "";
  }
}

class ValidationError extends Error {
  status: number;
  data?: unknown & { errors?: string[] | Record<string, string> };
  errors?: string[] | Record<string, string>;

  constructor({
    status,
    data,
  }: {
    status: number;
    data?: unknown & { errors?: string[] };
  }) {
    super();
    this.name = "ValidationError";
    this.status = status;
    this.data = data;
    this.message = data?.errors?.length ? data.errors[0] : "";
    this.errors = data?.errors;
  }
}

async function get(
  url: string,
  params?: SearchParamsTypes | Record<string, Object>,
  opts?: RequestInit,
): Promise<any> {
  if (params) {
    let urlParams: SearchParamsTypes;

    // Convert params to JSON if necessary.
    if (isObjectRecord(params)) {
      urlParams = new URLSearchParams();

      for (const key in params) {
        urlParams.append(key, JSON.stringify(params[key]));
      }
    } else {
      urlParams = params;
    }

    url += "?" + new URLSearchParams(urlParams);
  }

  return fetch(url, opts).then((resp) => resp.json());
}

async function post(url: string, data?: unknown): Promise<any> {
  return dataRequest(url, data, { method: "POST" });
}

async function put(url: string, data?: unknown): Promise<any> {
  return dataRequest(url, data, { method: "PUT" });
}

async function deleteMethod(url: string, data?: unknown): Promise<any> {
  return dataRequest(url, data, { method: "DELETE" });
}

async function dataRequest(
  url: string,
  data?: unknown,
  opts?: RequestInit,
): Promise<any> {
  const csrfToken = document
    .querySelector("meta[name='csrf-token']")
    ?.getAttribute("content");

  if (csrfToken == null) throw new Error("Could not find CSRF token.");

  return new Promise((resolve, reject) => {
    opts ??= { method: "POST" };
    opts.headers = { "X-CSRF-Token": csrfToken };

    if (data) {
      opts.body = JSON.stringify(data);
      opts.headers["Content-Type"] = "application/json";
    }

    fetch(url, opts)
      .then(async (resp) => {
        const isJSON = resp.headers
          .get("Content-Type")
          ?.includes("application/json");
        const data = await (isJSON ? resp.json() : resp.text());

        if (!resp.ok) {
          if (resp.status === 422) {
            // Unprocessable Entity
            return reject(new ValidationError({ status: resp.status, data }));
          } else if (resp.status === 403) {
            // Forbidden
            toast.error(HTMLMessage, {
              componentProps: {
                message:
                  "Operation not allowed.<br />Please contact an administrator.",
              },
            });
          } else if (resp.status === 500) {
            // Forbidden
            toast.error(HTMLMessage, {
              componentProps: {
                message: "Server error.<br />Please contact an administrator.",
              },
            });
          }

          return reject(new BackendError({ status: resp.status, data }));
        }

        return resolve(data);
      })
      .catch((err) => {
        // Client-side error.
        // TODO: send to Sentry
        console.error(err);
      });
  });
}

export async function validateTag(tag: Schema.Tag | NewRecord<Schema.Tag>) {
  return post(API.tags.validate.path(), {
    tag: { tag_field_id: tag.tag_field_id, value: tag.value },
  });
}

export default { get, post, put, delete: deleteMethod };
export type { PaginationData, PagedResponse };
export { BackendError, ValidationError };
