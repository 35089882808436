// JsFromRoutes CacheKey 1b62f08793335c75ab71d3ad1d07b221
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/forgot_password'),
  create: /* #__PURE__ */ definePathHelper('post', '/forgot_password'),
  edit: /* #__PURE__ */ definePathHelper('get', '/password_resets/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/password_resets/:id'),
}
