<script lang="ts">
  import type { HTMLTdAttributes } from "svelte/elements";
  import { cn } from "$lib/utils.js";

  type $$Props = HTMLTdAttributes;

  let className: $$Props["class"] = undefined;
  export { className as class };

  let element: HTMLTableCellElement;
</script>

<td
  class={cn("align-middle", className)}
  {...$$restProps}
  on:click
  on:keydown
  bind:this={element}
>
  <slot {element} />
</td>
