// JsFromRoutes CacheKey 779538ab078e91e6730162cbecefc484
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  clear: /* #__PURE__ */ definePathHelper('delete', '/bag_assets/clear'),
  destroyMultiple: /* #__PURE__ */ definePathHelper('delete', '/bag_assets/destroy_multiple'),
  index: /* #__PURE__ */ definePathHelper('get', '/bag_assets'),
  create: /* #__PURE__ */ definePathHelper('post', '/bag_assets'),
  new: /* #__PURE__ */ definePathHelper('get', '/bag_assets/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/bag_assets/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/bag_assets/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/bag_assets/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/bag_assets/:id'),
}
