<script lang="ts">
  import { cn } from "$lib/utils";

  import ArrowLeftIcon from "~icons/ph/arrow-left";

  export let title: string | null = null;
  export let subtitle: string | null = null;
  export let hasBackBtn: boolean = false;
  export let wrapperClass: string | undefined = undefined;
  export let headerClass: string | undefined = undefined;
  export let titleClass: string | undefined = undefined;
  export let contentClass: string | undefined = undefined;
  export let footerClass: string | undefined = undefined;

  wrapperClass = cn("py-6 px-8", wrapperClass);
  headerClass = cn("flex items-center", headerClass);
  titleClass = cn("flex", titleClass);
  contentClass = cn("flex-grow pt-4", contentClass);
  footerClass = cn("flex-shrink-0", footerClass);
</script>

<section class={wrapperClass}>
  <div class="flex items-center">
    {#if hasBackBtn}
      <button type="button" on:click={() => history.back()}>
        <ArrowLeftIcon class="me-3" />
      </button>
    {/if}

    <slot name="breadcrumb" />
  </div>

  {#if title || $$slots.actions}
    <header class={headerClass}>
      {#if title}
        <div class={titleClass}>
          {#if $$slots["header-icon"]}
            <slot name="header-icon" />
          {/if}

          <div class="flex-grow">
            <h1 class="text-2xl font-semibold leading-10">{title}</h1>
            {#if subtitle}
              <span class="text-sm text-zinc-500">{subtitle}</span>
            {/if}
          </div>
        </div>
      {/if}

      <div class="flex items-center ms-auto">
        <slot name="actions" />
      </div>
    </header>
  {/if}

  <main class={contentClass}>
    <slot />
  </main>

  {#if $$slots.footer}
    <footer class={footerClass}>
      <slot name="footer" />
    </footer>
  {/if}
</section>
