import { writable } from "svelte/store";

import API from "$api";
import backend, { ValidationError } from "$lib/backend";
import { toast } from "svelte-sonner";
import { HTMLMessage } from "$components/ui/sonner";

export const currentUser = writable<Schema.User | undefined>(undefined);

export async function updateUserPref<
  UserPrefKey extends keyof Schema.UserPreference,
>(key: UserPrefKey, value: Schema.UserPreference[UserPrefKey]) {
  return backend
    .put(API.userPreferences.update.path(), {
      user_preferences: { [key]: value },
    })
    .then((newUserPrefs: Schema.UserPreference) => {
      currentUser.update((user) => {
        if (user) user.preferences = newUserPrefs;
        return user;
      });
    })
    .catch((error) => {
      if (error instanceof ValidationError) {
        const userPrefs = error.data as Schema.UserPreference;

        if (userPrefs.errors) {
          const errors = userPrefs.errors[key];

          if (errors) {
            toast.error(errors[0]);
          }
        }
      }
    });
}
