<script lang="ts">
  import { getContext, hasContext } from "svelte";
  import { get } from "svelte/store";
  import type { HTMLInputAttributes } from "svelte/elements";

  import { cn } from "$lib/utils";

  import CameraIcon from "~icons/ph/camera-plus";

  type $$Props = HTMLInputAttributes & {
    name: string;
    value?: string;
    file?: File;
    hasPreview?: boolean;
    wrapperClass?: string;
    previewClass?: string;
  };

  export let name: string;
  export let value: string | undefined = undefined;
  export let file: File | undefined = undefined;
  export let hasPreview: boolean = false;
  export let wrapperClass: string | undefined = undefined;
  export let previewClass: string | undefined = undefined;

  let form: FormContext | undefined = undefined;
  let error: string | undefined = undefined;
  let previewUrl: string | undefined = undefined;
  let input: HTMLInputElement;

  wrapperClass = cn("flex items-center gap-2", wrapperClass);
  previewClass = cn(
    "group flex-shrink-0 flex items-center justify-center w-10 h-10 ring-2 ring-offset-2 ring-zinc-300 hover:ring-zinc-400 rounded-full hover:cursor-pointer object-cover",
    previewClass,
  );

  if (hasContext("form")) {
    form = getContext<FormContext>("form");

    form.subscribe((newRecord) => {
      error = newRecord.errors[name];
    });
  }

  function onInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!hasPreview || !target.files?.length) return;

    file = target.files[0];
    previewUrl = URL.createObjectURL(file);

    if (form && name in get(form).data()) {
      form.update((curr) => {
        return { ...curr, [name]: file };
      });
    }
  }
</script>

<div class={wrapperClass}>
  {#if hasPreview}
    {#if previewUrl || value}
      <img
        src={previewUrl ?? value}
        class={previewClass}
        on:click={() => input.click()}
      />
    {:else}
      <button type="button" class={previewClass} on:click={() => input.click()}>
        <CameraIcon class="text-2xl text-zinc-400 group-hover:text-zinc-500" />
      </button>
    {/if}
  {/if}

  <input
    type="file"
    class:hidden={hasPreview}
    {...$$restProps}
    on:change={onInputChange}
    bind:this={input}
  />
</div>
