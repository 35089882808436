<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { router, useForm } from "@inertiajs/svelte";
  import { toast } from "svelte-sonner";

  import API from "$api";

  import TeamForm from "$views/admin/teams/_form.svelte";

  import * as Dialog from "$components/ui/dialog";
  import { Button } from "$components/ui/button";
  import * as Toaster from "$components/ui/sonner";

  import XIcon from "~icons/ph/x";

  export let team: Schema.Team;

  const dispatch = createEventDispatcher<{ destroy: undefined }>();
  const form = useForm({
    name: team.name,
    image: team.image_data?.derivatives?.thumbnail
      ? `/web/${team.image_data?.derivatives?.thumbnail.id}`
      : undefined,
  });

  let open: boolean = false;
  let saving: boolean = false;

  onMount(() => {
    open = true;
  });

  function destroy() {
    open = false;
    // Emit `cancel` event after a delay to allow closing animation to finish.
    setTimeout(() => dispatch("destroy"), 400);
  }

  function onCancelClick() {
    destroy();
  }

  function onOpenChange(open: boolean) {
    if (!open) destroy();
  }

  function onSaveClick() {
    const data = new FormData();
    data.append("team[name]", $form.name);
    data.append("team[image]", $form.image);

    router.put(API.adminTeams.update.path({ id: team.id }), data, {
      onSuccess: () => {
        saving = false;
        open = false;
        toast.success(Toaster.HTMLMessage, {
          componentProps: {
            message: `Team <b>${$form.name}</b> was updated.`,
          },
        });
      },
    });

    saving = true;
  }
</script>

<Dialog.Root {onOpenChange} bind:open>
  <Dialog.Trigger />
  <Dialog.Portal>
    <Dialog.Overlay class="bg-black/60 backdrop-blur-none" />

    <Dialog.Content class="block p-0 bg-zinc-100 border-none overflow-hidden">
      <Dialog.Header class="flex-row space-y-0 bg-primary">
        <Dialog.Title class="p-4 text-zinc-50 font-semibold">
          Edit Team {team.name}
        </Dialog.Title>
        <Dialog.Close class="ms-auto">
          <XIcon />
        </Dialog.Close>
      </Dialog.Header>

      <div class="py-6 px-4">
        <TeamForm {form} />
      </div>

      <Dialog.Footer class="p-2 bg-zinc-200 border-t border-zinc-300">
        <Button
          variant="secondary"
          class="hover:bg-zinc-300"
          on:click={onCancelClick}
        >
          Cancel
        </Button>
        <Button on:click={onSaveClick}>Save</Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
