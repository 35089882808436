<script lang="ts">
  import { get } from "svelte/store";
  import { inertia } from "@inertiajs/svelte";

  import API from "$api";
  import { addToBag, bagAssets } from "$stores/bag";

  import BagIcon from "~icons/ph/bag";

  let dragOver: boolean = false;

  function onBagEnter(event: DragEvent) {
    dragOver = true;
  }

  function onBagOver() {
    dragOver = true;
  }

  function onBagLeave() {
    dragOver = false;
  }

  function onBagDrop(event: DragEvent) {
    dragOver = false;

    const data = event.dataTransfer?.getData("text");
    if (!data) return;

    try {
      const parsed = JSON.parse(data);

      if (areAssets(parsed)) {
        addToBag(parsed.assets as Schema.Asset[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function areAssets(data: any) {
    return data && data.assets;
  }
</script>

<div
  class="btn-action-bar {$$props.class ?? ''}"
  class:border-zinc-500={dragOver}
  class:bg-zinc-300={dragOver}
  on:dragenter|preventDefault={onBagEnter}
  on:dragover|preventDefault={onBagOver}
  on:dragleave={onBagLeave}
  on:drop|preventDefault={onBagDrop}
>
  <a
    href={API.bagAssets.index.path()}
    class="flex items-center h-full px-2"
    use:inertia
  >
    <BagIcon class="mt-0.5" />
    {#if $bagAssets.length}
      <span
        class="ms-1 px-1.5 bg-primary rounded-full text-primary-foreground text-xs font-bold"
      >
        {$bagAssets.length}
      </span>
    {/if}
  </a>
</div>
