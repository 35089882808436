import { get, writable } from "svelte/store";

import { range } from "$lib/utils";

export type SelectOptions = { shift: boolean; ctrl: boolean };

// A list of selectable item ids, a.k.a. Selection Items ids.
export const items = writable<number[]>([]);

// The selection is a set of Selection Item ids.
export const selection = writable<Set<number>>(new Set());

export const lastSelection = writable<number>(0);

export function registerItem(id: number) {
  items.update((currItems) => [...currItems, id]);
}

export function select(id: number, opts?: SelectOptions) {
  if (opts?.shift) return selectTo(id);

  selection.update((currSelection) => {
    if (opts?.ctrl) return currSelection.add(id);
    else return new Set([id]);
  });
  lastSelection.set(id);
}

// This function will update the Selection only if the given Selection Item id
// exists in the Selection. This is an optimization in order to avoid triggering
// a useless UI update.
export function unselect(id: number, opts?: SelectOptions) {
  if (opts?.shift) {
    return selectTo(id, { clear: true });
  }

  if (get(selection).delete(id)) {
    // Selection was already mutated in `delete` call above. Trigger an update
    // just by re-setting it.
    selection.update((currSelection) => currSelection);
  }
}

export function selectAll() {
  if (get(items).length === 0) return;

  selection.set(new Set(get(items)));
  lastSelection.set(0);
}

export function clear(opts?: { except?: number | number[] }) {
  let newSelection: number[] = [];

  if (opts?.except) {
    newSelection = Array.isArray(opts.except) ? opts.except : [opts.except];
  }

  selection.set(new Set(newSelection));
  lastSelection.set(newSelection[0] ?? 0);
}

// Select a range of items up to `id` included.
// Enabling the `clear` option will unselect all items that are not in the
// range.
export function selectTo(
  id: number,
  opts: { clear: boolean } = { clear: false },
) {
  const lastId = get(lastSelection);
  const itemIdRange = id >= lastId ? range(lastId, id) : range(id, lastId);

  if (!clear) lastSelection.set(id);

  selection.update((currSelection) => {
    const newSelection = opts.clear
      ? itemIdRange
      : [...Array.from(currSelection), ...itemIdRange];
    return new Set(newSelection);
  });
}
