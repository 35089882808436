<script context="module" lang="ts">
  export type Position = {
    x: number;
    y: number;
    width: number;
    height: number;
  };
</script>

<script lang="ts">
  import { onMount } from "svelte";
  import hotkeys from "hotkeys-js";

  import { cn, nextFrame } from "$lib/utils";
  import { portal } from "$lib/actions";

  import XIcon from "~icons/ph/x-circle-fill";

  export let contentWidth: number;
  export let contentHeight: number;
  export let originElement: Element | undefined = undefined;
  export let isOpen: boolean = false;
  export let hasBackdrop: boolean = false;
  export let hasChrome: boolean = false;
  export let title: string | undefined = undefined;
  export let wrapperClass: string | undefined = undefined;
  export let backdropClass: string | undefined = undefined;
  export let animationDuration: number = 300;

  const hasChromeScaleFactor = 0.9;

  let wrapper: HTMLDivElement;
  let frame: HTMLElement;
  let content: HTMLElement;
  let prevHotKeysScope: string;

  wrapperClass = cn("z-[61] relative hidden", $$props.class);
  backdropClass = cn(
    "fixed inset-0 bg-black/75 transition-opacity ease-out duration-300",
    backdropClass,
  );

  // Resize content if it overflows the viewport.
  // This block is executed when the content width/height props are updated.
  $: {
    let aspectRatio = contentWidth / contentHeight;
    let [vpWidth, vpHeight] = getViewportDim();

    // Compute relative difference of each dimension between content and
    // viewport.
    let diffWidth = contentWidth / vpWidth;
    let diffHeight = contentHeight / vpHeight;

    if (contentWidth >= vpWidth || contentHeight >= vpHeight) {
      // Content is bigger than the viewport, must resize.

      if (diffWidth > diffHeight) {
        // Bigger width difference --> constrain width
        contentWidth = vpWidth;
        contentHeight = contentWidth / aspectRatio;
      } else {
        // Bigger height difference or equal difference --> constrain height
        contentHeight = vpHeight;
        contentWidth = contentHeight * aspectRatio;
      }

      if (hasChrome) {
        // Add some margin to draw the chrome.
        contentWidth *= hasChromeScaleFactor;
        contentHeight *= hasChromeScaleFactor;
      }
    }
  }

  onMount(() => {
    // Remove any previously defined hot keys to refresh any stale references
    // inside them, such as `close` here.
    hotkeys.deleteScope("lightbox");
    hotkeys("space, escape", { scope: "lightbox" }, function () {
      close();
      return false;
    });
  });

  export async function open() {
    grabHotKeys();
    await animate();

    isOpen = true;
  }

  export async function close() {
    releaseHotKeys();
    await animate();

    isOpen = false;
  }

  function getViewportDim(): [number, number] {
    return [
      document.documentElement.clientWidth,
      document.documentElement.clientHeight,
    ];
  }

  // This function computes the center coordinates of the content relative to
  // the viewport.
  function getCenterCoordinates(): [number, number] {
    const [vpWidth, vpHeight] = getViewportDim();
    return [(vpWidth - contentWidth) / 2, (vpHeight - contentHeight) / 2];
  }

  function grabHotKeys() {
    prevHotKeysScope = hotkeys.getScope();
    hotkeys.setScope("lightbox");
  }

  function releaseHotKeys() {
    hotkeys.setScope(prevHotKeysScope);
  }

  async function animate() {
    return isOpen ? animateClose() : animateOpen();
  }

  async function animateClose() {
    if (originElement) scale();
    fade();

    // Wait for the scale animation to end before hiding the wrapper.
    setTimeout(() => {
      wrapper.classList.add("hidden");
    }, animationDuration);
  }

  async function animateOpen() {
    position();

    wrapper.classList.remove("hidden");

    // Wait next frame, otherwise component won't animate.
    await nextFrame();

    if (originElement) scale();
    fade();
  }

  // This function resizes and places the frame to the content dimensions.
  // If there is an origin element, it scales the frame and positions it
  // accordingly. In this case, the frame will animate to the content dimensions
  // using the `scale` function.
  function position() {
    const [x, y] = getCenterCoordinates();

    // Set content dimensions to content element, not to frame because frame
    // must "wrap" the content. In other words, we want the content to determine
    // the frame dimensions.
    content.style.width = contentWidth + "px";
    content.style.height = contentHeight + "px";

    frame.style.top = y + "px";
    frame.style.left = x + "px";

    if (originElement) positionOnOrigin();
  }

  function positionOnOrigin() {
    if (!originElement) return;

    const [x, y] = getCenterCoordinates();
    const {
      x: originX,
      y: originY,
      width,
      height,
    } = originElement.getBoundingClientRect();
    const aspectRatio = contentWidth / contentHeight;

    let scale = 1;
    let offsetX = 0;
    let offsetY = 0;

    if (aspectRatio >= 1) {
      // Landscape or square
      scale = width / contentWidth;
    } else {
      // Portrait
      scale = height / contentHeight;
    }

    // Compute offset relative to origin element.
    offsetX = originX - x;
    offsetY = originY - y;

    frame.style.translate = `${offsetX}px ${offsetY}px`;
    frame.style.scale = scale.toString();
    frame.style.transformOrigin = "0px 0px";
  }

  // For animation purpose. This function scales the frame from the origin
  // element dimensions (if any) to the content dimensions if the lightbox is
  // opening. Or in the inverse direction if the lightbox is closing.
  function scale() {
    if (!originElement) return;

    if (isOpen) {
      // Lightbox is closing.
      positionOnOrigin();
    } else {
      // Lightbox is opening.
      frame.style.translate = "0px 0px";
      frame.style.scale = "1";
    }
  }

  function fade() {
    frame.style.opacity = isOpen ? "0" : "100%";
  }
</script>

<div class={wrapperClass} use:portal bind:this={wrapper}>
  {#if hasBackdrop}
    <div
      class={backdropClass}
      class:opacity-0={!isOpen}
      class:opacity-100={isOpen}
    >
      <!-- Lightbox backdrop -->
    </div>
  {/if}

  <div class="fixed inset-0 overflow-hidden">
    <section
      class="absolute flex flex-col w-fit p-1.5 bg-zinc-100 border border-zinc-300 shadow rounded-lg transition-all ease-in-out pointer-events-none overflow-hidden"
      class:-mt-5={hasChrome}
      style:transition-duration={animationDuration}
      bind:this={frame}
    >
      {#if hasChrome}
        <header
          class="flex-shrink-0 flex items-center w-full h-4 -mt-1.5 py-4 pointer-events-auto"
        >
          <div class="flex-shrink-0 flex items-center gap-1 pr-1">
            <button type="button" on:click|stopPropagation={close}>
              <XIcon class="w-5 h-5 text-zinc-600 text-sm" />
            </button>
          </div>

          {#if title}
            <div
              class="text-zinc-600 text-xs font-bold truncate"
              style:max-width={`${contentWidth * 0.75}px`}
            >
              {title}
            </div>
          {/if}

          <div class="flex-shrink-0 flex items-center gap-1 ms-auto pl-1">
            <slot name="actions" />
          </div>
        </header>
      {/if}

      <main
        class="content flex-grow flex items-center justify-center w-full h-full min-h-0"
        bind:this={content}
      >
        <slot width={contentWidth} height={contentHeight} />
      </main>
    </section>
  </div>
</div>

<style>
  .content > :global(*) {
    @apply border rounded-lg shadow overflow-hidden;
  }
</style>
