// JsFromRoutes CacheKey bac25e7a9863ea3d13380a63b05db181
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/assets/:asset_id/comments'),
  create: /* #__PURE__ */ definePathHelper('post', '/assets/:asset_id/comments'),
  new: /* #__PURE__ */ definePathHelper('get', '/assets/:asset_id/comments/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/assets/:asset_id/comments/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/assets/:asset_id/comments/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/assets/:asset_id/comments/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/assets/:asset_id/comments/:id'),
}
