<script lang="ts">
  import * as Avatar from "$components/ui/avatar";

  export let team: Schema.Team;
</script>

<Avatar.Root class={$$props.class ?? ""}>
  <Avatar.Image src={team.image?.url} />
  <Avatar.Fallback>
    {team.name.substring(0, 2).toUpperCase()}
  </Avatar.Fallback>
</Avatar.Root>
